import moment from 'moment'
import React from 'react'
import { MOMENT_FORMATS } from 'src/utilities/constants'

export default function BookedEventDurationHandler({ item }) {
  return (
    <>
      {moment
        .utc(item?.startTime)
        .local()
        .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)}{' '}
      {moment.utc(item?.startTime).local().format(MOMENT_FORMATS.formattedTime24Hrs)} -{' '}
      {moment.utc(item?.endTime).local().format(MOMENT_FORMATS.formattedTime24Hrs)} (Duration:{' '}
      {item?.eventDuration / 60} mins)
    </>
  )
}
