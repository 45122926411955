import { Add } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import CCExtendedCSmartTable, {
  CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS,
} from 'src/components/controlCenter/CCExtendedCSmartTable'
import ListingComponentFilterBar from 'src/components/controlCenter/ListingComponentFilterBar'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { MOMENT_FORMATS } from 'src/utilities/constants'
import PaginatedListingTableHandlerWrapper from '../PaginatedListingTableHandlerWrapper'
import GeneratePaymentLinkForm from './GeneratePaymentLinkForm'
import { GeneratePaymentLinkDialogPaper } from './styledComponents'

export default function UserPaymentLinks({ userId, dropDownData }) {
  const [data, setData] = useState({
    paymentLinks: {
      data: [],
      page: 0,
      size: 20,
      status: 'idle',
    },
  })
  const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false)
  const [paymentLinkModalData, setPaymentLinkModalData] = useState()

  const paymentLinksData = data?.paymentLinks

  function setDataDynamically(prevData, key) {
    if (typeof prevData === 'function') {
      setData((prev) => ({
        ...prev,
        [key]: prevData(prev?.[key]),
      }))
    } else {
      setData((prev) => ({
        ...prev,
        [key]: prevData,
      }))
    }
  }

  function setPaymentLinksData(paymentLinksData) {
    setDataDynamically(paymentLinksData, 'paymentLinks')
  }

  function openPaymentLinkModal(data) {
    setShowPaymentLinkModal(true)
    data ? setPaymentLinkModalData(data) : setPaymentLinkModalData(null)
  }
  function closePaymentLinkModal() {
    setShowPaymentLinkModal(false)
  }

  async function refreshPaymentLink() {
    setPaymentLinksData((prev) => ({ ...prev, status: 'loading' }))
    const resp = await GetSettingsData(USERS_API_SIGNATURES_MAP.paymentLinks.linksDetails.get, {
      userId: userId,
      size: 200,
    })
    if (resp.status === 200) {
      setPaymentLinksData((prev) => ({ ...prev, data: resp.data.content, status: 'success' }))
    } else {
      setPaymentLinksData((prev) => ({ ...prev, status: 'error' }))
    }
  }

  function filterComponentFunction(props) {
    return (
      <ListingComponentFilterBar>
        <div className="w-100 d-flex justify-content-between">
          <div className="w-100"></div>
          <IconButton
            onClick={() => {
              openPaymentLinkModal()
            }}
          >
            <Add />
          </IconButton>
        </div>
      </ListingComponentFilterBar>
    )
  }

  return (
    <PaginatedListingTableHandlerWrapper
      apiCallFunction={GetSettingsData}
      apiCallUrl={USERS_API_SIGNATURES_MAP.paymentLinks.linksDetails.get}
      apiCallParams={{ userId: userId, size: 50 }}
      data={paymentLinksData?.data}
      setData={setPaymentLinksData}
      setupDataDependencies={[userId]}
      filterComponentFunction={filterComponentFunction}
      isPaginated
    >
      <CCExtendedCSmartTable
        noUserNames
        items={paymentLinksData?.data}
        loading={paymentLinksData?.status === 'loading'}
        columns={columns}
        onRowClick={openPaymentLinkModal}
        clickableRows
        scopedColumns={scopedColumns}
        excludeGenericFields={[
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.title,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedTime,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.modifiedBy,
          CC_EXTENDED_SMART_TABLE_DEFAULT_COLUMNS_KEYS.actions,
        ]}
        tableTagProps={{
          hover: true,
        }}
      />
      <Dialog
        open={showPaymentLinkModal}
        onClose={closePaymentLinkModal}
        PaperComponent={GeneratePaymentLinkDialogPaper}
      >
        <GeneratePaymentLinkForm
          initialPaymentLinkData={paymentLinkModalData}
          closePaymentLinkModal={closePaymentLinkModal}
          refreshAllPaymentLinks={refreshPaymentLink}
          dropDownData={dropDownData}
        />
      </Dialog>
    </PaginatedListingTableHandlerWrapper>
  )
}

const columns = [
  { key: 'pkgId', label: 'Package Id', sorter: false, filter: false },
  { key: 'amount', label: 'Amount', sorter: false, filter: false },
  { key: 'currency', label: 'Currency', sorter: false, filter: false },
  { key: 'status', label: 'Status', sorter: false, filter: false },
  { key: 'platform', label: 'Platform', sorter: false, filter: false },
  { key: 'paymentLink', label: 'Link', sorter: false, filter: false },
  { key: 'paymentLinkCreatedTime', label: 'Link Created Time', sorter: false, filter: false },
  { key: 'createdTime', label: 'Created Time', sorter: false, filter: false },
]

const scopedColumns = {
  paymentLinkCreatedTime: (item) => {
    return (
      <td>
        {item?.paymentLinkCreatedTime
          ? moment(item?.paymentLinkCreatedTime).format(
              MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm,
            )
          : '--'}
      </td>
    )
  },
  createdTime: (item) => {
    return (
      <td>
        {moment(item?.createdTime).format(MOMENT_FORMATS.completeTimeDetailsWithMonthShortForm)}
      </td>
    )
  },
}
