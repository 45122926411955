import { Grid } from '@mui/material'
import React from 'react'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import { APPLICABLE_TO_ENTITY_ARRAY, currency_list } from 'src/utilities/constants'
import ReferenceFeeRange from './components/ReferenceFeeRange'
import PayoutList from './components/PayoutList'
import { getChangedDataForSetters } from 'src/utilities/generalUtils'

export default function FeeModelForm({
  initialObject,
  imageObject: feeModelForm,
  setImageObject: setFeeModelForm,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  extraActionButtonsRefObject,
  refreshViewedItem,
  closeDialog,
  refreshListing,
}) {
  function handleChangeFeeRange(e) {
    handleNestedChangeObject(e, 'refFeeRange')
  }

  function setPayoutList(dataPrev) {
    setFeeModelForm((prev) => ({
      ...prev,
      payoutList: getChangedDataForSetters(dataPrev, prev?.payoutList || []),
    }))
  }

  return (
    <div className="d-flex flex-column gap-2">
      <GridSectionWrapper heading="Reference Fee Range">
        <ReferenceFeeRange
          feeRange={feeModelForm?.refFeeRange}
          handleChangeFeeRange={handleChangeFeeRange}
          disabled={disabled}
        />
      </GridSectionWrapper>
      <GridSectionWrapper heading="Model Info">
        <Grid item xs={12} md={4}>
          <CCFormInput
            id="title"
            value={feeModelForm?.title}
            onChange={handleChangeObject}
            label="Internal Title"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="countryCode"
            value={customOptions?.countries?.find((ctry) => ctry?.id === feeModelForm?.countryCode)}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => {
              const foundCurrency = currency_list?.find(
                (curr) => curr?.code === optn?.currency,
              ) || { code: optn?.currency }

              return `${optn?.title} - ${foundCurrency?.symbol || foundCurrency?.code || ''}`
            }}
            onChangeEvent={(e) => {
              setFeeModelForm((prev) => ({
                ...prev,
                countryCode: e?.target?.value?.id,
                currencyCode: e?.target?.value?.currency,
              }))
            }}
            selectType={selectTypes.object}
            label="Country"
            required
            options={[
              ...customOptions?.countries,
              {
                id: 'row',
                currency: 'usd',
                title: 'Rest Of the World',
              },
            ]}
            disabled={disabled}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="currencyCode"
            value={feeModelForm?.currencyCode}
            getOptionValue={(optn) => optn?.code}
            getOptionLabel={(optn) => `${optn?.name} (${optn?.symbol})`}
            onChangeEvent={handleChangeObject}
            selectType={selectTypes.string}
            label="Currency"
            options={currency_list}
            disabled={disabled}
          />
        </Grid> */}
        <Grid item xs={12} md={4}>
          <CustomReactSelect
            id="applicableTo"
            value={feeModelForm?.applicableTo}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            onChangeEvent={handleChangeObject}
            selectType={selectTypes.string}
            label="Applicable To"
            options={APPLICABLE_TO_ENTITY_ARRAY}
            disabled={disabled}
            required
          />
        </Grid>
      </GridSectionWrapper>
      <GridSectionWrapper heading="Payout List">
        <PayoutList
          payoutList={feeModelForm?.payoutList}
          setPayoutList={setPayoutList}
          disabled={disabled}
        />
      </GridSectionWrapper>
    </div>
  )
}
