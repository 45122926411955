import { setClinicPostFunctionAndParams } from 'src/config/clinic/post/setPostFunctionAndParams'
import { getClinicAssistantPostFunctionAndParams } from 'src/config/clinicAssistant/post/functionAndParams'
import { setClinicBrandPostFunctionAndParams } from 'src/config/clinicBrand/post/getFunctionAndParams'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { getAgreementsPostFunctionAndParams } from 'src/config/genericListing/agreement/functionAndParametersTransform'
import { fetchCurrentClinicChambers } from 'src/reducers/clinicFlowData/services/clinicChambers/clinicChambers'
import store from 'src/store'
import { getFeeModelPostFunctionAndParams } from '../feeModel/getFeeModelPostFunctionAndParams'

export function postFunctionSettings(apiParam, dataToSend, extraProps) {
  const { storeData, refresh, defaultApiSignature, defaultParams } = extraProps

  const configToReturn = {
    functionAndParams: {
      url: `${defaultApiSignature}/${apiParam}`,
      params: {},
      isACommonResponseApi: false,
    },
    afterSaveScript: (entityData, dataBeforeSaving) => {},
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES.agreement:
      configToReturn.functionAndParams = getAgreementsPostFunctionAndParams(
        defaultApiSignature,
        apiParam,
      )
      break
    case ALL_ENTITY_TYPES.clinicBrand:
      setClinicBrandPostFunctionAndParams(configToReturn)
      break
    case ALL_ENTITY_TYPES.clinicChamber:
      configToReturn.afterSaveScript = async (chamberData, dataBeforeSaving) => {
        store.dispatch(fetchCurrentClinicChambers())
      }
      break
    case ALL_ENTITY_TYPES.clinic:
      setClinicPostFunctionAndParams(configToReturn)
      break
    case ALL_ENTITY_TYPES.clinicAssistant:
      configToReturn.functionAndParams = getClinicAssistantPostFunctionAndParams(
        defaultApiSignature,
        apiParam,
      )
      break
    case ALL_ENTITY_TYPES.feeModel:
      configToReturn.functionAndParams = getFeeModelPostFunctionAndParams(
        defaultApiSignature,
        apiParam,
      )
      break
    case ALL_ENTITY_TYPES.entityFeeModel:
      configToReturn.functionAndParams = {
        isACommonResponseApi: true,
        url: 'fee-model/entity/save',
      }
      break
    default:
      break
  }
  return configToReturn
}
