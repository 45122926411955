import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CCExtendedCSmartTable from 'src/components/controlCenter/CCExtendedCSmartTable'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS, MOMENT_FORMATS } from 'src/utilities/constants'

export default function DetailComponent({ bookedEventInfo, clinicBrand }) {
  console.log({ clinicBrand })

  return (
    <div className="w-100 h-100">
      {/* SR: add more details here */}
      {clinicBrand && (
        <div>
          <strong>Clinic Brand: </strong>
          {clinicBrand?.brandName || clinicBrand?.title}
        </div>
      )}
      <div>
        <strong>Duration: </strong>
        {bookedEventInfo?.eventDuration / 60} mins
      </div>
      <div>
        <strong>Start Time: </strong>
        {moment
          .utc(bookedEventInfo?.startTime)
          .local()
          .format(MOMENT_FORMATS.completeTimeDetails)}
      </div>
      <div>
        <strong>End Time: </strong>
        {moment
          .utc(bookedEventInfo?.endTime)
          .local()
          .format(MOMENT_FORMATS.completeTimeDetails)}
      </div>
      <div>
        <strong>Booked For mentor: </strong>
        {bookedEventInfo?.mentorName}
      </div>
      <div>
        <strong>with client: </strong> {bookedEventInfo?.userName}
      </div>
      <div>
        <strong>Completion:</strong>{' '}
        {bookedEventInfo?.markedCompleted ? 'Completed' : 'Not Completed'}
      </div>
      {bookedEventInfo?.modificationHistory && bookedEventInfo?.modificationHistory?.length > 0 && (
        <>
          <h5 className="mt-3 mb-2">Modification History</h5>
          {bookedEventInfo?.modificationHistory?.map((history, idx) => {
            return (
              <div key={idx}>
                <div>
                  Appointment Number Before Modification:{' '}
                  {history?.appointmentNumberBeforeModification}
                </div>
                <div>Event: {history?.event}</div>
                <div>
                  Start Time before Modification:{' '}
                  {moment
                    .utc(history?.startTimeBeforeModification)
                    .local()
                    .format(MOMENT_FORMATS.completeTimeDetails)}
                </div>
                <div>
                  Modified At:{' '}
                  {moment
                    .utc(history?.modifiedAt)
                    .local()
                    .format(MOMENT_FORMATS.completeTimeDetails)}
                </div>
                <hr></hr>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}
