import { COLORS_OBJECT } from 'src/components/controlCenter/imageUpload/imageUploadUtils'

export const OOH_MESSAGES_OBJECT = {
  id: '',
  entityItemType: '',
  enabled: true,
  deleted: false,
  idByUser: false,
  slug: '',
  colors: COLORS_OBJECT,
  apiParam: 'ooh-msg',
  modifiedBy: '',
  title: '',
  subText: '',
  imageId: '',
  imageUrl: '',
  imageType: '',
  userId: '',
  orderId: 0,
  mentorId: '',
  clinicId: '',
  clinicBrandId: '',
  orgIds: [],
  orgType: '',
  infoType: '',
}

export function getChangedOohMsgs(initMsgs, msgsToCompare) {
  const msgToComp = [...msgsToCompare]
  const initialOohMsgs = [...initMsgs]

  const changedOohMsgs = msgToComp?.filter((oohMsg) => {
    const foundMsg = initialOohMsgs?.find((msg) => {
      return msg?.id === oohMsg?.id
    })
    if (isMsgChanged(oohMsg, foundMsg)) {
      return true
    } else {
      return false
    }
  })

  return changedOohMsgs
}

function isMsgChanged(oohMsg, foundMsg) {
  // Check if any of the overridden properties have changed
  if (
    oohMsg?.id !== foundMsg?.id ||
    oohMsg?.title !== foundMsg?.title ||
    oohMsg?.youtubeUrl !== foundMsg?.youtubeUrl ||
    oohMsg?.durationInSec !== foundMsg?.durationInSec ||
    oohMsg?.orderId !== foundMsg?.orderId ||
    oohMsg?.createdTime !== foundMsg?.createdTime ||
    oohMsg?.createdBy !== foundMsg?.createdBy ||
    oohMsg?.modifiedTime !== foundMsg?.modifiedTime ||
    oohMsg?.modifiedBy !== foundMsg?.modifiedBy
  ) {
    return true
  }

  return false
}
