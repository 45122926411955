import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'

export default function CancelComponent({ bookedEventInfo, afterActionFunction }) {
  const [sendCommunicationToClient, setSendCommunicationToClient] = useState(true)
  const [sendCommunicationToMentor, setSendCommunicationToMentor] = useState(true)
  const [cancelling, setCancelling] = useState(false)

  async function cancelSession() {
    setCancelling(true)
    const response = await PostSettingsData(
      'booked-event/cancel',
      {},
      {
        bookedEventId: bookedEventInfo?.id,
        sendCommunicationToClient: sendCommunicationToClient,
        sendCommunicationToMentor: sendCommunicationToMentor,
      },
    )
    setCancelling(false)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      toast.success('Booking cancelled')
    } else {
      toast.error(`Failed: ${response?.data?.message}`)
    }
    afterActionFunction && afterActionFunction()
  }

  return (
    <div className="w-100 h-100 d-flex flex-column gap-2">
      <div className="mt-3">
        Scheduled for:{' '}
        <b>
          {moment.utc(bookedEventInfo?.startTime).local().format('DD MMM YYYY hh:mm a')} -{' '}
          {moment.utc(bookedEventInfo?.endTime).local().format('DD MMM YYYY hh:mm a')}
        </b>
      </div>
      <div>
        Mentor/Doctor/Therapist: <b>{bookedEventInfo?.mentorName}</b>
      </div>
      <div>
        Client/Patient: <b>{bookedEventInfo?.userName}</b>
      </div>
      {bookedEventInfo?.status === 'booked' ? (
        <>
          <FormControlLabel
            sx={{ marginTop: '32px' }}
            labelPlacement="end"
            control={
              <Checkbox
                id="sendCommunicationToClient"
                checked={sendCommunicationToClient}
                onChange={(e) => {
                  setSendCommunicationToClient(e.target.checked)
                }}
              />
            }
            label="Send Communication to Client"
          />
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                id="sendCommunicationToMentor"
                checked={sendCommunicationToMentor}
                onChange={(e) => {
                  setSendCommunicationToMentor(e.target.checked)
                }}
              />
            }
            label="Send Communication to Mentor"
          />
          <Button
            sx={{ width: 'fit-content', marginTop: '32px' }}
            variant="contained"
            color="error"
            onClick={cancelSession}
          >
            {'Cancel'}
            {cancelling && (
              <CircularProgress size={'16px'} sx={{ color: '#fff', marginLeft: '8px' }} />
            )}
          </Button>
        </>
      ) : (
        <div style={{ color: 'red' }}>
          This session has already been cancelled at{' '}
          {moment.utc(bookedEventInfo?.cancelledTime).local().format('DD MMM YYYY hh:mm a')}
        </div>
      )}
    </div>
  )
}
