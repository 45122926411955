import { Button, Grid, IconButton } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import React from 'react'
import { CCFormInput } from 'src/components/controlCenter/FormComponents'
import { getChangedDataForSetters, giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { FEE_PAYOUT_OBJECT } from 'src/common/types/feeModel/FeePayout'

export default function PayoutList({ payoutList, setPayoutList, disabled }) {
  function setPayoutListItem(dataPrev, idx) {
    setPayoutList((prev) => {
      return (prev || [])?.map((item, index) => {
        if (index === idx) {
          return getChangedDataForSetters(dataPrev, item)
        }
        return item
      })
    })
  }

  function deletePayoutItem(idxToDelete) {
    setPayoutList((prev) => {
      return (prev || [])?.filter((item, index) => {
        if (index === idxToDelete) {
          return false
        }
        return true
      })
    })
  }
  function addPayoutItem() {
    setPayoutList((prev) => {
      return [...(prev || []), FEE_PAYOUT_OBJECT]
    })
  }

  return (
    <>
      {payoutList?.map((payoutItem, idx) => (
        <Grid item xs={12} key={idx}>
          <PayoutItem
            key={idx}
            idx={idx}
            disabled={disabled}
            isLastItem={idx === (payoutList?.length || 0) - 1}
            item={payoutItem}
            setPayoutItem={(dPrev) => setPayoutListItem(dPrev, idx)}
            deletePayoutItem={() => deletePayoutItem(idx)}
          />
        </Grid>
      ))}
      {!disabled && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={addPayoutItem}
            endIcon={<Add />}
            variant="contained"
            sx={{ padding: '8px 80px' }}
          >
            Add
          </Button>
        </Grid>
      )}
    </>
  )
}

function PayoutItem({ item, setPayoutItem, deletePayoutItem, idx, isLastItem, disabled }) {
  function handleChange(e) {
    setPayoutItem((prev) => giveGenericHandleChangedData(e, prev))
  }

  return (
    <div className="d-flex align-items-center gap-1">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CCFormInput
            value={item?.startId}
            id="startId"
            type="number"
            required={idx !== 0}
            onChange={handleChange}
            disabled={disabled}
            label="Start Id"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CCFormInput
            value={item?.endId}
            id="endId"
            type="number"
            required={!isLastItem}
            onChange={handleChange}
            disabled={disabled}
            label="End Id"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CCFormInput
            value={item?.partnerFee}
            id="partnerFee"
            type="number"
            onChange={handleChange}
            disabled={disabled}
            label="Partner Fee (%)"
          />
        </Grid>
      </Grid>
      {!disabled && (
        <IconButton onClick={deletePayoutItem}>
          <Delete />
        </IconButton>
      )}
    </div>
  )
}
