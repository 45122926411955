import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

export const defaultConfirmationModalProps = {
  visibility: false,
  visibilitySetter: null,
  action: '',
  body: '',
  buttonColor: 'success',
  onSubmitFunctions: [],
  submitButtonText: 'Confirm',
  closeButtonText: 'Close',
}

export default function ConfirmationModal({
  newImplementation = false,
  visibility,
  visibilitySetter,
  action,
  body,
  buttonColor,
  onSubmitFunctions,
  submitButtonText = 'Confirm',
  closeButtonText = 'Close',
}) {
  function handleVisibilitySetter(value) {
    if (newImplementation) {
      visibilitySetter((prev) => ({ ...prev, visibility: false }))
    } else {
      visibilitySetter(value)
    }
  }

  async function handleSubmit() {
    handleVisibilitySetter(false)
    await Promise.all(
      onSubmitFunctions.map(async (func) => {
        const AsyncFunction = (async () => {}).constructor
        const GeneratorFunction = function* () {}.constructor
        //checks if the function is an async function
        if (
          (func instanceof AsyncFunction &&
            AsyncFunction !== Function &&
            AsyncFunction !== GeneratorFunction) === true
        ) {
          await func()
          return 1
        } else {
          func()
          return 1
        }
      }),
    )
  }

  const finalButtonColor = buttonColor ? buttonColor : action === 'submit' ? 'success' : 'error'
  return (
    <Dialog
      open={visibility}
      onClose={() => {
        handleVisibilitySetter(false)
      }}
    >
      <DialogTitle>
        <h4>
          <strong>Confirm {action}?</strong>
        </h4>
      </DialogTitle>
      {body !== '' && body !== undefined && (
        <DialogContent>
          <div className="d-flex flex-column">{body}</div>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="secondary" onClick={() => handleVisibilitySetter(false)}>
          {closeButtonText}
        </Button>
        <Button color={finalButtonColor} variant="contained" onClick={handleSubmit}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
