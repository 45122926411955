import { Info } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { getPriceStringWithCurrency, isValidNumber } from 'src/utilities/generalUtils'
import FeeModelViewDetails from './components/FeeModelViewDetails'
import { ModelViewDialogPaper } from './components/styledComponents'

export default function PayoutDistributionReportModelDetails({
  report,
  dropDownData,
  reduxStore,
  getSharePercent,
  getShareAmount,
  getEntityTitle,
  getEntityFeeModelDetails,
  getFeeModelDetails,
  getCurrencyCode,
}) {
  const [showModelDetails, setShowModelDetails] = useState(false)
  const itemTitle = getEntityTitle(report)
  const sharePercent = getSharePercent(report)
  const shareAmount = getShareAmount(report)

  const entityFeeModel = getEntityFeeModelDetails(report)
  const feeModel = getFeeModelDetails(report)

  const currencyCode = getCurrencyCode(report)

  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-1">
        <div className="d-flex flex-column gap-2">
          <span>{itemTitle || 'No Title'}</span>
          <span>
            Share Amount:{' '}
            {isValidNumber(shareAmount)
              ? getPriceStringWithCurrency(shareAmount, currencyCode)
              : 'No Data'}{' '}
            ({!!sharePercent ? `${sharePercent}%` : 'No Data'})
          </span>
        </div>
        {entityFeeModel && feeModel && (
          <IconButton
            size="small"
            onClick={() => {
              setShowModelDetails(true)
            }}
          >
            <Info />
          </IconButton>
        )}
      </div>
      <Dialog
        PaperComponent={ModelViewDialogPaper}
        open={showModelDetails}
        onClose={() => {
          setShowModelDetails(false)
        }}
      >
        <FeeModelViewDetails entityFeeModel={entityFeeModel} feeModel={feeModel} showModelTitle />
      </Dialog>
    </>
  )
}
