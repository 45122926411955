import moment from 'moment/moment'
import { DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { APPLICABLE_TO_ENTITY, MOMENT_FORMATS } from 'src/utilities/constants'
import { StyledPayoutListView } from './styledComponents'
import { getOrdinalSuffix } from 'src/utilities/generalUtils'

export default function FeeModelViewDetails({ feeModel, entityFeeModel, showModelTitle = false }) {
  return (
    <>
      <DialogTitle className="mb-2">
        {showModelTitle ? (
          <span>{entityFeeModel?.title || 'No Title'}</span>
        ) : (
          <span>
            {APPLICABLE_TO_ENTITY?.[entityFeeModel?.entityType]?.title || 'Others'} Fee Model
          </span>
        )}
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column gap-2">
          <ViewEntityFeeModel entityFeeModel={entityFeeModel} showModelTitle={showModelTitle} />
          <ViewFeeModel feeModel={feeModel} />
        </div>
      </DialogContent>
    </>
  )
}

function ViewEntityFeeModel({ entityFeeModel }) {
  return (
    <div className="d-flex flex-column">
      <span>
        <strong>Validity: </strong>
        <span>
          {moment
            .utc(entityFeeModel?.applicableFrom)
            .local()
            .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)}{' '}
          -{' '}
          {entityFeeModel?.applicableTill
            ? moment
                .utc(entityFeeModel?.applicableTill)
                .local()
                .format(MOMENT_FORMATS.completeDateDetailsWithMonthShortForm)
            : 'Currenty Active'}
        </span>
      </span>
    </div>
  )
}
function ViewFeeModel({ feeModel }) {
  return (
    <div className="d-flex flex-column gap-2">
      <h4>Model Details</h4>
      <div className="d-flex flex-column gap-1">
        <StyledPayoutListView style={{ borderBottom: '1px solid black', marginBottom: '8px', fontWeight:600 }}>
          <span>Sessions</span>
          <span>Partner Fee</span>
        </StyledPayoutListView>
        {feeModel?.payoutList.map((payout, idx) => {
          if (payout?.startId === payout?.endId) {
            return (
              <StyledPayoutListView key={idx}>
                <span>
                  {getOrdinalSuffix(payout?.startId)} Session
                </span>
                <span>{payout?.partnerFee}%</span>
              </StyledPayoutListView>
            )
          }

          if(!payout?.endId){
            return (
              <StyledPayoutListView key={idx}>
                <span>
                  {getOrdinalSuffix(payout?.startId)} Session and Onwards
                </span>
                <span>{payout?.partnerFee}%</span>
              </StyledPayoutListView>
            )
          }

          return (
            <StyledPayoutListView key={idx}>
              <span>
                {getOrdinalSuffix(payout?.startId)} {payout?.endId - payout?.startId > 1 ? "to" : 'and'} {getOrdinalSuffix(payout?.endId)} Session
              </span>
              <span>{payout?.partnerFee}%</span>
            </StyledPayoutListView>
          )
        })}
      </div>
    </div>
  )
}
