import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import UserInputComponent, {
  UserInputOption,
  UserMultiValueLabelOption,
  UserSelectedOption,
} from 'src/components/controlCenter/userInput/UserInputComponent'
import { apiURL } from 'src/services'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import { isCommonResponseSuccessful, loadOptionsForAsyncPaginate } from 'src/utilities/generalUtils'
import UsersBasicDetailsTab from 'src/views/roleBasedEntities/users/BasicDetailsTab/UsersBasicDetailsTab'
import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'
import { v4 as uuidv4 } from 'uuid'

export default function SendBundleToUser({
  //   apiParam,
  //   listingData: states,
  currentItem,
  handleClose,
  handleRefresh,
  customOptions,
  storeData,
}) {
  const [selectedUser, setSelectedUser] = useState()
  const [loading, setLoadingUsers] = useState(false)
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  const [sending, setSending] = useState(false)

  const [onboardingName, setOnboardingName] = useState()

  async function sendBundleToUser() {
    const tst = toast.loading('Sending payment link...')
    const resp = await PostSettingsData(
      'end-user/plan-bundle-pack/send-payment-link',
      {},
      {
        'to-user-id': selectedUser?.id,
        'bundle-pack-id': currentItem?.id,
        'client-transaction-id': uuidv4(),
        'language-code': "en'",
        'country-code': selectedUser?.location?.countryCode || 'in',
      },
    )
    if (resp.status === 200) {
      if (isCommonResponseSuccessful(resp.data?.code)) {
        setShowSuccessDialog(true)
        toast.update(tst, {
          render: 'Bundle sent successfully!',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 2000,
        })
        return
      }
      toast.update(tst, {
        render: `Error: ${resp.data?.message}`,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 2000,
      })
    }
    toast.update(tst, {
      render: `Something went wrong!`,
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 2000,
    })
    setSending(false)
  }

  function showConfirmationModal() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      aciton: 'send',
      body: `Are you sure you want to send ${currentItem?.title} bundle payment link to ${selectedUser?.firstName}?`,
      buttonColor: 'primary',
      onSubmitFunctions: [
        () => {
          sendBundleToUser()
        },
      ],
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      submitButtonText: 'Send pyament Link',
    }))
  }

  console.log({ customOptions })

  return (
    <>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Send {currentItem?.title} bundle to a user.</span>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>Select User</div>
        <ActivityItemAsyncSelect
          classNamePrefix="activity-item-select"
          menuPortalTarget={document.body}
          isClearable
          value={selectedUser ? selectedUser : null}
          loadOptions={loadOptionsForAsyncPaginate}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.firstName}
          components={{
            Option: UserInputOption,
            SingleValue: UserSelectedOption,
            MultiValueLabel: UserMultiValueLabelOption,
          }}
          debounceTimeout={1000}
          additional={{
            size: 30,
            getFunction: GetSettingsData,
            apiUrl: 'end-user/list/all/paginated',
            setLoading: setLoadingUsers,
          }}
          onChange={(selVal) => setSelectedUser(selVal)}
        />
        <h5 className="mt-3 mb-2">Selected User Details</h5>
        {selectedUser && (
          <UsersBasicDetailsTab
            disabled
            key={selectedUser}
            userObject={selectedUser}
            onboardingName={onboardingName}
            setOnboardingName={setOnboardingName}
            hideContentPreferences
            hideOtherDetails
            hide
            customOptions={{ ...customOptions, ...storeData }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={!selectedUser} variant="contained" onClick={showConfirmationModal}>
          Send
        </Button>
      </DialogActions>
      <Dialog
        open={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false)
        }}
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <span>
            {currentItem?.title} bundle sent to {selectedUser?.firstName} successfully!
          </span>
        </DialogTitle>
        <DialogContent>
          <a
            // href={`/subscriptions/payment-links?userId=${selectedUser?.id}`}
            href={`/subscriptions/payment-links`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Payment Links Listing
          </a>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSuccessDialog(false)
            }}
          >
            Send Another
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowSuccessDialog(false)
              handleRefresh()
              handleClose()
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
