import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getSummarizableObject } from '../abstractObjects/Summarizable'
import { FEE_PAYOUT_OBJECT } from './FeePayout'

export const FEE_MODEL_OBJECT = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.feeModel),
  countryCode: 'in',
  currencyCode: 'inr',
  applicableTo: '',
  refFeeRange: null,
  payoutList: [FEE_PAYOUT_OBJECT],
}
