import React, { useEffect, useState } from 'react'
import CancelComponent from './CancelComponent'
import DetailComponent from './DetailsComponent'
import ReassignComponent from './ReassignComponent'
import RescheudleComponent from './RescheduleComponent'
import { Button } from '@mui/material'
import { StyledTabButton, StyledTabContainer } from 'src/components/controlCenter/styledComponents'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'

export default function BookedEventsForm({
  initialObject,
  imageObject: bookedEventData,
  setImageObject: setBookedEventData,
  entity,
  handleChangeObject,
  handleReactSelectChange,
  handleImageChange,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  allImages,
  handleRichTextChange,
  customOptions,
  disabled,
  extraActionButtonsRefObject,
  refreshViewedItem,
  closeDialog,
  refreshListing,
}) {
  const TAB_DETAILS = 'details'
  const TAB_RESCHEDULE = 'reschedule'
  const TAB_CANCEL = 'cancel'
  const TAB_REASSIGN = 'reassign'

  const [selectedTab, setSelectedTab] = useState(TAB_DETAILS)
  const [clinicBrand, setClinicBrand] = useState()

  async function getClinicBrand() {
    const resp = await GetSettingsData(GENERIC_APIS.entity.details, {
      id: bookedEventData?.clinicBrandId,
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.clinicBrand,
    })
    if (resp.status === 200) {
      setClinicBrand(resp.data)
    }
  }

  useEffect(() => {
    if (bookedEventData?.clinicBrandId) {
      getClinicBrand()
    }
  }, [bookedEventData])

  return (
    <div>
      <StyledTabContainer
        sx={{
          background: (theme) =>
            `${
              theme.palette.mode === 'dark'
                ? 'var(--cui-body-bg)'
                : 'rgba(var(--cui-light-rgb), var(--cui-bg-opacity))'
            }`,
          borderBottom: '1px solid var(--cui-border-color)',
          padding: '15px 0px',
        }}
      >
        <StyledTabButton
          selected={selectedTab === TAB_DETAILS}
          onClick={() => setSelectedTab(TAB_DETAILS)}
        >
          Details
        </StyledTabButton>
        <StyledTabButton
          selected={selectedTab === TAB_RESCHEDULE}
          onClick={() => setSelectedTab(TAB_RESCHEDULE)}
        >
          Reschedule
        </StyledTabButton>
        <StyledTabButton
          selected={selectedTab === TAB_CANCEL}
          onClick={() => setSelectedTab(TAB_CANCEL)}
        >
          Cancel
        </StyledTabButton>
        <StyledTabButton
          selected={selectedTab === TAB_REASSIGN}
          onClick={() => setSelectedTab(TAB_REASSIGN)}
        >
          Reassign
        </StyledTabButton>
      </StyledTabContainer>
      <div className="mt-1">
        {selectedTab === TAB_DETAILS && (
          <DetailComponent bookedEventInfo={bookedEventData} clinicBrand={clinicBrand} />
        )}
        {selectedTab === TAB_RESCHEDULE && (
          <RescheudleComponent
            bookedEventInfo={bookedEventData}
            afterActionFunction={() => {
              refreshViewedItem()
              refreshListing()
            }}
          />
        )}
        {selectedTab === TAB_CANCEL && (
          <CancelComponent
            bookedEventInfo={bookedEventData}
            afterActionFunction={() => {
              refreshViewedItem()
              refreshListing()
            }}
          />
        )}
        {selectedTab === TAB_REASSIGN && (
          <ReassignComponent
            bookedEventInfo={bookedEventData}
            afterActionFunction={() => {
              refreshViewedItem()
              refreshListing()
            }}
          />
        )}
      </div>
    </div>
  )
}
