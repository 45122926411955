import React from 'react'
import { getRoles, openInNewTab, validateField } from 'src/utilities/generalUtils'
import { ObjectId } from 'src/utilities/generators'
import { AVATAR_IMAGE_OBJECT } from 'src/views/settings/utils/avatarUtils'
import { ICONS_OBJECT, META_IMAGES_OBJECT } from 'src/views/settings/utils/imageGallery-utils'
import { SUBTITLE_OBJECT } from 'src/views/settings/utils/subtitle-utils'
import {
  AGREEMENT_OBJECT,
  BLOG_OBJECT,
  USER_BADGE_OBJECT,
  USER_MENTAL_STATE_OBJECT,
  USER_MENTAL_STATE_TYPE_OBJECT,
  USER_STATS_OBJECT,
  validateID,
} from 'src/views/setup/userStats/stats-utils'
import {
  BasicSubtitleView,
  ImageFormImagePreview,
  RichTextPreview,
} from '../../components/controlCenter/EntityComponents/EntityComponents'
import {
  filterData,
  getAndAttachReflectionToQuote,
} from '../../components/controlCenter/EntityComponents/utils'
import SubtitleGallery from '../../components/controlCenter/SubtitleGallery'
import ImageGallery from '../../components/controlCenter/imageUpload/ImageGallery'

import { CButton, CButtonGroup, CFormInput } from '@coreui/react-pro'
import {
  Add,
  Close,
  DateRange,
  FilterAlt,
  FitnessCenter,
  PlusOne,
  PointOfSale,
  Reorder,
  Science,
  Settings,
  SwapVert,
} from '@mui/icons-material'
import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import 'country-flag-icons/3x2/flags.css'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import { CLINIC_ASSISTANT_OBJECT } from 'src/common/types/clinicAssistant/clinicAssistant'
import { CLINIC_BRAND_OBJECT } from 'src/common/types/clinicBrand/clinicBrandObject'
import { CLINIC_CHAMBERS_OBJECT } from 'src/common/types/clinicChambers/clinicChambersObject'
import { COURSE_CMS_FIELD_TYPE_OBJECT } from 'src/common/types/courseCmsFieldType/courseCmsFieldTypeObject'
import { DRUG_ADVICE_DURATION_OBJECT } from 'src/common/types/drugAdvice/drugAdviceDurationObject'
import { DRUG_ADVICE_FREQUENCY_OBJECT } from 'src/common/types/drugAdvice/drugAdviceFrequencyObject'
import { DRUG_ADVICE_NOTE_OBJECT } from 'src/common/types/drugAdvice/drugAdviceNoteObject'
import { DRUG_ADVICE_TIMING_OBJECT } from 'src/common/types/drugAdvice/drugAdviceTimingObject'
import { FAQ_SCOPE_OBJECT } from 'src/common/types/faqScopes/faqScopeObject'
import { GUEST_OBJECT } from 'src/common/types/guest/guestObject'
import { IN_APP_MSG_OBJECT } from 'src/common/types/inAppMsgs/inAppMsgObject'
import { INSTRUCTION_OBJECT } from 'src/common/types/instruction/instructionObject'
import { INVESTIGATION_OBJECT } from 'src/common/types/investigation/investigationObject'
import { INVESTIGATION_SAMPLE_OBJECT } from 'src/common/types/investigation/investigationSampleObject'
import { NOTIFICATION_OBJECT } from 'src/common/types/notifications/notificationsObject'
import { ONLINE_COURSE_OBJECT } from 'src/common/types/onlineCourse/onlineCourseObject'
import { PAYMENT_GATEWAY_SKU } from 'src/common/types/paymentGatewaySku/paymentGatewaySkuObject'
import { PAYMENT_GATEWAYS } from 'src/common/types/paymentGateways/paymentGateways'
import { PAYWALL_OBJECT } from 'src/common/types/paywall/paywallObject'
import { PLAN_BUNDLE_PACKS_OBJECT } from 'src/common/types/planBundlePacks/planBundlePacksObject'
import { META_PLAN_GROUPS } from 'src/common/types/planGroups/metaPlanGroups'
import { PRESCRIPTION_TEMPLATES_OBJECT } from 'src/common/types/prescriptionTemplate/prescriptionTemplateObject'
import { SATELLITE_SCREEN_OBJECT } from 'src/common/types/satelliteScreen/satelliteScreenObject'
import { SES_TEMPLATE_OBJECT } from 'src/common/types/sesTemplate/sesTemplateObject'
import { META_SUBSCRIPTION_TYPES } from 'src/common/types/subscriptionTypes/metaSubscriptionTypes'
import { SUPPORT_USER_OBJECT } from 'src/common/types/supportUser/supportUserObject'
import { TAB_SETTINGS_OBJECT } from 'src/common/types/tabSettings/tabSettingsObject'
import AutomaticListAddingComponent from 'src/components/controlCenter/common/AutomaticListAddingComponent'
import { fetchFunction, fetchPaginatedFunction } from 'src/services'
import approveClinicBrand from 'src/services/HealthCare/clinicProducts/clinicBrands/approveClinicBrand'
import { COURSE_CMS_FORM_FIELDS_SECTIONS_API_SIGNATURE_MAP } from 'src/services/apiSignatureMaps/onlineCourse/courseConfig/apis'
import { POLICIES_API_SIGNATURE_MAP } from 'src/services/apiSignatureMaps/policies/apis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { GENERIC_APIS } from 'src/services/genericApis'
import store from 'src/store'
import { GetDetailedEntity, SaveEntity } from 'src/utilities/apiGetters'
import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS,
  ARTICLE_TYPES,
  GENERIC_DEFAULT_ACTIONS,
  INVESTIGATION_TEST_TYPES_ARRAY,
  INVESTIGATION_TYPES_ARRAY,
  LISTING_VIEWS,
  META_CATEGORY_SCOPE_TYPES,
  MOMENT_FORMATS,
  ONLINE_COURSE_AUTOPLAY_TYPE,
  ONLINE_COURSE_MEDIA_TYPES_ARRAY,
  ONLINE_COURSE_STATUSES,
  ONLINE_COURSE_TYPES,
  SUBSCRIPTION_TYPES_LAYMAN,
  TAG_TYPE,
} from 'src/utilities/constants'
import SegmentsFormOld from 'src/views/appBehaviour/segments/SegmentsFormWithOldFields'
import SegmentTester from 'src/views/appBehaviour/segments/segmentTester/SegmentTester'
import TabSettingsComponent from 'src/views/appBehaviour/tabSettings/TabSettingsComponent'
import SesTemplateEditor from 'src/views/communication/ses/SesTemplateEditor'
import {
  publishOnlineCourseApiCall,
  unPublishOnlineCourseApiCall,
} from 'src/views/courses/courseForm/api_utils'
import { getActionButtonViewStatusForOnlineCourse } from 'src/views/courses/courseForm/utils'
import ArticleViewComponent from 'src/views/features/articles/ArticleViewComponent'
import { HINT_OBJECT } from 'src/views/features/hints/utils/hints-utils'
import { ASSESSMENT_OBJECT, JOURNAL_OBJECT } from 'src/views/features/journals/utils/journals-utils'
import { QUOTE_OBJECT } from 'src/views/features/quotes/utils/quotes-utils'
import RestFeatureComponent from 'src/views/features/rest/RestFeatureComponent'
import { REST_OBEJCT } from 'src/views/features/rest/rest-utils'
import WebinarEditingForm from 'src/views/features/webinars/WebinarEditingForm'
import { WEBINAR_OBJECT } from 'src/views/features/webinars/webinar-utils'
import ClinicAssistantForm from 'src/views/healthcare/clinicAssistant/ClinicAssistantForm'
import ClinicBrandForm from 'src/views/healthcare/clinicBrand/ClinicBrandForm'
import ClinicChamberForm from 'src/views/healthcare/clinicChambers/ClinicChamberForm'
import DrugAdviceNoteForm from 'src/views/healthcare/drugAdviceNote/DrugAdviceNoteForm'
import ReorderDrugAdviceItems from 'src/views/healthcare/drugAdviceNote/components/ReorderDrugAdviceItems'
import InstructionForm from 'src/views/healthcare/instructions/InstructionForm'
import InvestigationForm from 'src/views/healthcare/investigation/InvestigationForm'
import MultiUploadInvestigations from 'src/views/healthcare/investigation/MultiUploadInvestigations'
import PrescriptionTemplateForm from 'src/views/healthcare/prescriptionTemplates/PrescriptionTemplateForm'
import NotificationsComponent from 'src/views/marketing/notifications/NotificationsComponent'
import InAppMessagesComponent from 'src/views/miscellaneous/in-app-messages/InAppMessagesComponent'
import Page404 from 'src/views/pages/page404/Page404'
import { APIs } from 'src/views/plugins/calendar/components/api-utils'
import ClinicAssistantUserRoleAddition from 'src/views/roleBasedEntities/clinicAssistantUsers/components/clinicAssistantUserRoleAddition/ClinicAssistantUserRoleAddition'
import GuestListComponent from 'src/views/roleBasedEntities/guest/GuestListComponent'
import MedicalBoardFormComponent from 'src/views/roleBasedEntities/medicalBoard/MedicalBoardFormComponent'
import MetaUserRoles from 'src/views/roleBasedEntities/metaUserRoles/MetaUserRoles'
import SupportUserComponent from 'src/views/roleBasedEntities/supportUser/SupportUserComponent'
import UsersFormComponent from 'src/views/roleBasedEntities/users/UsersFormComponent'
import SupportStaffForm from 'src/views/settings/SupportStaffForm'
import { AVAILABLE_SERVICES_OPTIONS } from 'src/views/settings/components/ClinicDetails'
import DrugInfoForm from 'src/views/settings/components/DrugInfoForm'
import { SETTINGS_API_LIST } from 'src/views/settings/utils/api-utils'
import { getCategoryScopes } from 'src/views/settings/utils/category-type'
import { META_CATEGORY_TYPE_OBJECT } from 'src/views/settings/utils/category-utils'
import { DIAGNOSIS_OBJECT } from 'src/views/settings/utils/diagnosis-utils'
import {
  DISPLAY_MESSAGE_TEMPLATES_OBJECT,
  SENTINEL_CARD_OBJECT,
} from 'src/views/settings/utils/display-message-template'
import { DRUG_INFO_OBJECT } from 'src/views/settings/utils/drug-utils'
import { HEALTH_COMPLAINT_OBJECT } from 'src/views/settings/utils/health-complaint-utils'
import { META_TAG_OBJECT } from 'src/views/settings/utils/meta-tag-utls'
import { ROLE_OBJECT } from 'src/views/settings/utils/role_utils'
import { SUPPORT_STAFF_OBJECT } from 'src/views/settings/utils/support-staff-utils'
import {
  TEACHER_OBJECT,
  approveClinic,
  approveTeacher,
  getApprovedCondition,
} from 'src/views/settings/utils/teacher-utils'
import { TILES_OBJECT } from 'src/views/setup/tiles/utils/tile-utils'
import ReorderMentalState from 'src/views/setup/userStats/components/ReorderMentalState'
import { CoolerTabs, FilterPopover } from 'src/views/setup/userStats/components/styledComponents'
import { SubscriptionApis } from 'src/views/subscriptions/api-utils'
import {
  ACTIVITY_OBJECT,
  ACTIVITY_PLAN_OBJECT,
  PHASE_OBJECT,
} from 'src/views/subscriptions/customPlans/utils'
import FeatureBundleForm from 'src/views/subscriptions/featureBundleForm/FeatureBundleForm'
import PaymentGatewaySkuForm from 'src/views/subscriptions/paymentGatewaySkus/PaymentGatewaySkuForm'
import PaymentGatewaysForm from 'src/views/subscriptions/paymentGateways/PaymentGatewaysForm'
import PaywallsForm from 'src/views/subscriptions/paywalls/PaywallsForm'
import BackgroundMusicComponent from 'src/views/tracks/backgroundMusic/BackgroundMusicComponent'
import PlanPhaseComponent from 'src/views/userFeatures/PlanPhase/PlanPhaseComponent'
import ActivityComponent from 'src/views/userFeatures/activityPlan/ActivityComponent'
import DisplayMsgTemplateComponent from 'src/views/userFeatures/displayMsgTemplate/DisplayMsgTemplate'
import OohMessagesForm from 'src/views/userFeatures/oohMessages/OohMessagesForm'
import ReorderOohMsgs from 'src/views/userFeatures/oohMessages/components/ReorderOoh'
import { OOH_MESSAGES_OBJECT } from 'src/views/userFeatures/oohMessages/ooh-message-utils'
import { StyledListingSearchBar } from '../../components/controlCenter/EntityComponents/styledComponents'
import { CustomReactSelect, selectTypes } from '../../components/controlCenter/FormComponents'
import { TOAST_UTILS } from '../../components/controlCenter/toast-utils'
import { CLINIC_OBJECT } from '../../views/settings/utils/clinic-utils'
import CacheEvictHeaderFirstLayer from '../cacheEvict/genericForm/headerComponents/cacheEvict/FirstLayer'
import PaymentGatewayHeaderFirstLayer from '../cacheEvict/genericForm/headerComponents/paymentGateway/FirstLayer'
import PaymentGatewaySKUHeaderFirstLayer from '../cacheEvict/genericForm/headerComponents/paymentGatewaySku/FirstLayer'
import UsersHeaderComponentsFirstLayer from '../cacheEvict/genericForm/headerComponents/users/FirstLayer'
import { getExtraScopedColumnsForSmartTable } from '../genericListing/columnsAndHeaders/getExtraScopedColumnsForSmartTable'
import { getListOfColumnsForSmartTable } from '../genericListing/columnsAndHeaders/getListOfColumnsForSmartTable'
import SendBundleToUser from 'src/views/subscriptions/plans/components/BundlePack/sendBundleToUser/SendBundleToUser'
import BookedEventFilters from 'src/components/controlCenter/genericListing/bookedEventInfo/BookedEventFilters'
import BookedEventsForm from 'src/views/events/bookedEvent/BookedEventsForm'
import FeeModelForm from 'src/views/feeModel/feeModelPage/FeeModelForm'
import { FEE_MODEL_OBJECT } from 'src/common/types/feeModel/FeeModel'
import { ENTITY_FEE_MODEL } from 'src/common/types/feeModel/EntityFeeModel'
import EntityFeeModelForm from 'src/views/feeModel/entityFeeModel/EntityFeeModelForm'
import PayoutDistributionReportsHeaderFirstLayer from '../cacheEvict/genericForm/headerComponents/payoutDistributionReports/PayoutDistributionReportsHeaderFirstLayer'

// function testerFunction() {
//   alert('working')
// }
const roleArray = getRoles()

export const ALL_ENTITY_TYPES = ALL_ENTITY_TYPES_FROM_CONSTANTS

export const POPUP_ACTION_STATES_TYPES = {
  reorderMentalState: 'reorderMentalState',
  reorderOohMsgs: 'reorderOohMsgs',
  segmentTester: 'segmentTester',
  clinicAssistantAddition: 'clinicAssistantAddition',
  multiUploadInvestigations: 'multiUploadInvestigations',
  reorderDrugAdviceAvailableToAll: 'reorderDrugAdviceAvailableToAll',
  sendBundleToUser: 'sendBundleToUser',
}

export const ViewComponents = {
  articleForm: ArticleViewComponent,
  activityComponent: ActivityComponent,
  basicSubtitle: BasicSubtitleView,
  clinicBrandComponent: ClinicBrandForm,
  clinicAssistantForm: ClinicAssistantForm,
  clinicChamberForm: ClinicChamberForm,
  drugInfoForm: DrugInfoForm,
  displayMgsTemplateComponent: DisplayMsgTemplateComponent,
  featureBundleComponent: FeatureBundleForm,
  imageView: ImageFormImagePreview,
  richText: RichTextPreview,
  supportStaffForm: SupportStaffForm,
  restComponentForm: RestFeatureComponent,
  planPhaseComponent: PlanPhaseComponent,
  oohComponent: OohMessagesForm,
  webinarForm: WebinarEditingForm,
  inAppMessagesForm: InAppMessagesComponent,
  notificationsComponent: NotificationsComponent,
  metaTagSettingsComponent: TabSettingsComponent,
  prescriptionTemplateForm: PrescriptionTemplateForm,
  paywallsForm: PaywallsForm,
  paymentGatewaysForm: PaymentGatewaysForm,
  paymentGatewaySKUForm: PaymentGatewaySkuForm,
  segmentsForm: SegmentsFormOld,
  // segmentsForm: SegmentsForm,
  usersForm: UsersFormComponent,
  backgroundMusicComponent: BackgroundMusicComponent,
  medicalBoardFormComponent: MedicalBoardFormComponent,
  guestList: GuestListComponent,
  supportUserForm: SupportUserComponent,
  sesTemplateForm: SesTemplateEditor,
  roleForm: MetaUserRoles,
  investigationsForm: InvestigationForm,
  drugAdviceItemsForm: DrugAdviceNoteForm,
  instructionsForm: InstructionForm,
  bookedEventsForm: BookedEventsForm,
  feeModelForm: FeeModelForm,
  entityFeeModelForm: EntityFeeModelForm,
}

export function PopupActionStatesComponents(type) {
  switch (type) {
    case POPUP_ACTION_STATES_TYPES.reorderMentalState:
      return ReorderMentalState
    case POPUP_ACTION_STATES_TYPES.reorderOohMsgs:
      return ReorderOohMsgs
    case POPUP_ACTION_STATES_TYPES.segmentTester:
      return SegmentTester
    case POPUP_ACTION_STATES_TYPES.clinicAssistantAddition:
      return ClinicAssistantUserRoleAddition
    case POPUP_ACTION_STATES_TYPES.multiUploadInvestigations:
      return MultiUploadInvestigations
    case POPUP_ACTION_STATES_TYPES.reorderDrugAdviceAvailableToAll:
      return ReorderDrugAdviceItems
    case POPUP_ACTION_STATES_TYPES.sendBundleToUser:
      return SendBundleToUser
    default:
      return Page404
  }
}

export const IMAGE_GALLERY_PAGES = {
  choose: 'choose',
  gallery: 'gallery',
  selectSize: 'size',
  imageForm: 'form',
  localUpload: 'local',
}

export const changeType = selectTypes

export const GalleryComponent = {
  imageGallery: ImageGallery,
  subtitleGallery: SubtitleGallery,
}

//FOR FORM RENDERING///////////////////////////////////////////////////

export function entityTitle(apiParam) {
  switch (apiParam) {
    case 'images':
      return {
        title: 'Add Details for the Image',
        subtitle: 'Add/Edit Images',
        uploadTitle: 'Upload Image',
      }
    case 'avatar':
      return {
        title: 'Add Details for the Avatar',
        subtitle: 'Add/Edit Avatar',
        uploadTitle: 'Upload Avatar',
      }
    case 'subtitle':
      return {
        title: 'Add Details for the Subtitle',
        subtitle: 'Add/Edit Subtitle',
        uploadTitle: 'Upload Subtitle',
      }
    case 'icon':
      return {
        title: 'Add Details for the Icon',
        subtitle: 'Add/Edit Icon',
        uploadTitle: 'Upload Icon',
      }
    case 'badge':
      return {
        title: 'Add Details for the badge',
      }
    case 'user-stats':
      return {
        title: 'Add Details for the user data',
      }
    case ALL_ENTITY_TYPES.mentalState:
      return {
        title: 'Add details/description for mental state',
      }
    case ALL_ENTITY_TYPES.mentalStateType:
      return {
        title: 'Add details/description for mental state type',
      }
    case ALL_ENTITY_TYPES.article:
      return {
        title: 'Add details/description for this article',
      }
    case 'meta-category-type':
      return {
        title: 'Add details for this category type',
      }
    case 'tag':
      return {
        title: 'Add details for this Tag',
      }
    case 'agreement':
      return {
        title: 'Add details for this agreement',
      }
    case 'role':
      return {
        title: 'Add details for this role',
      }
    case ALL_ENTITY_TYPES.oohMsg:
      return {
        title: 'Add details for this OOH Message',
      }
    default:
      return {
        title: `Add details for this entity(${apiParam})`,
      }
  }
}

export const entityChooseGalleryTitle = {
  images: {
    innerHtml: '<span>From</span> <h5>Image Gallery</h5>',
  },
  avatar: {
    innerHtml: '<span>From</span> <h5>Avatar Gallery</h5>',
  },
  subtitle: {
    innerHtml: '<span>From</span> <h5>Subtitle Repository</h5>',
  },
  icon: {
    innerHtml: '<span>From</span> <h5>Icon Gallery</h5>',
  },
}

export function entityFormComponents(entity, rStore = store.getState()) {
  switch (entity) {
    case 'images':
      return {
        switches: [
          { id: 'hasAlpha', label: 'Is Transparent' },
          { id: 'hasText', label: 'Image Has Text' },
          { id: 'quoteEnabled', label: 'Enabled For Quotes' },
          { id: 'hasBorder', label: 'Image has Border' },
        ],
        inputs: [
          { id: 'altText', label: 'Alternative Text', placeholder: 'Enter Alternative Text...' },
          { id: 'collection', label: 'Collection', placeholder: 'Enter Collection name...' },
        ],
        reactSelects: [
          {
            id: 'tags',
            label: 'Tags',
            placeholder: 'Enter Tags...',
            noOptionsMessage: () => 'Enter Tags',
            components: { DropdownIndicator: null },
            dropDownOptionId: 'tags',
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title,
          },
        ],
        fileInput: {
          accept: 'image/*',
          label: 'Upload Image',
        },
        ViewEditComponent: { component: ImageFormImagePreview, props: {} },
        GalleryComponent: { component: GalleryComponent.imageGallery },
      }
    case 'avatar':
      return {
        switches: [
          { id: 'hasAlpha', label: 'Is Transparent' },
          { id: 'hasText', label: 'Image Has Text' },
          { id: 'quoteEnabled', label: 'Enabled For Quotes' },
          { id: 'hasBorder', label: 'Image has Border' },
        ],
        htmlSelect: [
          {
            id: 'gender',
            label: 'Gender',
            placeholder: 'Select Gender',
            dropDownOptionId: 'gender',
          },
        ],
        inputs: [
          { id: 'altText', label: 'Alternative Text', placeholder: 'Enter Alternative Text...' },
          { id: 'collection', label: 'Collection', placeholder: 'Enter Collection name...' },
        ],
        reactSelects: [
          {
            id: 'tags',
            label: 'Tags',
            placeholder: 'Enter Tags...',
            noOptionsMessage: () => 'Enter Tags',
            components: { DropdownIndicator: null },
            dropDownOptionId: 'tags',
          },
        ],
        fileInput: {
          accept: 'image/*',
          label: 'Upload Avatar',
        },
        ViewEditComponent: { component: ImageFormImagePreview, props: {} },
      }
    case 'subtitle':
      return {
        switches: [],
        inputs: [
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', invalid: validateField },
        ],
        reactSelects: [
          {
            id: 'languageCode',
            label: 'Subtitle Language',
            placeholder: 'Select Language...',
            isMulti: false,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.languageName,
            dropDownOptionId: 'languages',
          },
        ],
        fileInput: {
          accept: '.vtt,.srt,.txt',
          label: 'Upload Subtitle',
        },
        htmlSelect: [
          {
            id: 'type',
            label: 'File Type',
            placeholder: 'Enter Subtitle Type...',
            col: 2,
            dropDownOptionId: 'subTitleType',
          },
        ],
        ViewEditComponent: { component: ViewComponents.basicSubtitle, props: { bgColor: false } },
        GalleryComponent: { component: GalleryComponent.subtitleGallery },
      }
    case 'icon':
      return {
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter the id of the icon...',
            disabledFunction: disableIdFieldIfMade,
          },
          { id: 'name', label: 'Name', placeholder: 'Enter Name...' },
          { id: 'key', label: 'Key', placeholder: 'Enter Key...' },
        ],
        fileInput: {
          accept: 'image/*',
          label: 'Upload Icon',
        },
        ViewEditComponent: { component: ImageFormImagePreview, props: {} },
      }
    case 'track':
      return {
        inputs: [
          { id: 'title', label: 'Title', placeholder: 'Enter Title...' },
          { id: 'duration', label: 'Duration', placeholder: 'Enter Duration...' },
        ],
        htmlSelect: [
          {
            id: 'type',
            label: 'Type',
            placeholder: 'Select Type...',
            dropDownOptionId: 'trackType',
          },
        ],
      }
    case 'badge':
      return {
        switches: [
          { id: 'enabled', label: 'Enabled' },
          { id: 'earned', label: 'Earned' },
        ],
        textArea: [
          { id: 'description', label: 'Description', placeholder: 'Enter Description...', row: 3 },
        ],
        inputs: [
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 4 },
          {
            id: 'maxCount',
            label: 'Max Count',
            placeholder: 'Enter a number...',
            type: 'number',
            required: true,
            col: 3,
          },
        ],
      }
    case ALL_ENTITY_TYPES.userStats:
      return {
        switches: [{ id: 'enabled', label: 'Enabled' }],
        htmlSelect: [
          {
            id: 'valueType',
            label: 'Value Type',
            placeholder: 'Select Value Type...',
            dropDownOptionId: 'statValueType',
          },
        ],
        inputs: [
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 3 },
          {
            id: 'sectionTitle',
            label: 'Section Title',
            placeholder: 'Enter Section Title...',
            col: 4,
          },
        ],
      }
    case ALL_ENTITY_TYPES.mentalState:
      return {
        switches: [
          {
            id: 'enabled',
            label: 'Enabled',
            disabledFunction: () => {
              return disableForContentWriter()
            },
          },
        ],
        inputs: [
          { id: 'name', label: 'Name', placeholder: 'Enter Name...', required: true, col: 4 },
        ],
        textArea: [
          {
            id: 'description',
            label: 'Description',
            placeholder: 'Enter Description...',
            row: 3,
          },
        ],
        reactSelects: [
          {
            id: 'type',
            label: 'Mental State Type',
            placeholder: 'Select a type...',
            isMulti: false,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'mentalStateTypes',
            col: 3,
            changeType: changeType.string,
          },
        ],
        imageUpload: true,
      }
    case ALL_ENTITY_TYPES.mentalStateType:
      return {
        switches: [
          {
            id: 'enabled',
            label: 'Enabled',
            disabledFunction: () => {
              return disableForContentWriter()
            },
          },
        ],
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
            disabledFunction: disableIdFieldIfMade,
          },
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 4 },
          { id: 'question', label: 'Question', placeholder: 'Enter Question...', col: 6 },
          { id: 'answer', label: 'Answer', placeholder: 'Enter Answer...', col: 6 },
        ],
        nestedNestedInputs: [
          {
            id: 'dailyPlayAltText',
            title: 'Daily Play Alternative Text',
            fields: {
              ...rStore?.timeSlots?.reduce((nestedArray, timeSlot) => {
                const outerLabel = timeSlot?.title || timeSlot?.name
                const questionProps = {
                  outerId: timeSlot.id,
                  id: 'question',
                  label: `${outerLabel}(${timeSlot.id}) Question`,
                  placeholder: 'Enter Question...',
                  col: 6,
                }
                const answerProps = {
                  outerId: timeSlot.id,
                  id: 'answer',
                  label: `${outerLabel}(${timeSlot.id}) Answer`,
                  placeholder: 'Enter Answer...',
                  col: 6,
                }
                nestedArray = {
                  ...nestedArray,
                  [outerLabel]: { label: null, value: [questionProps, answerProps] },
                }
                return nestedArray
              }, {}),
            },
          },
        ],
      }
    case ALL_ENTITY_TYPES.article:
      return {
        // switches: [
        //   {
        //     id: 'enabled',
        //     label: 'Enabled',
        //     disabledFunction: () => {
        //       return disableForContentWriter()
        //     },
        //   },
        // ],
        // inputs: [
        //   {
        //     id: 'title',
        //     label: 'Title',
        //     placeholder: 'Enter title...',
        //   },
        // ],

        // textArea: [
        //   {
        //     id: 'shortDesc',
        //     label: 'Short Description',
        //     placeholder: 'Enter Short Description...',
        //     row: 3,
        //   },
        // ],
        // reactSelects: [
        //   {
        //     id: 'primaryTags',
        //     label: 'Primary Tags',
        //     placeholder: 'Select Tag...',
        //     isMulti: true,
        //     getOptionValue: (option) => option.id,
        //     getOptionLabel: (option) => option.title || option.name,
        //     dropDownOptionId: 'tags',
        //     col: 6,
        //     changeType: changeType.string,
        //     disabledFunction: disableForContentWriter,
        //   },
        //   {
        //     id: 'secondaryTags',
        //     label: 'Secondary Tags',
        //     placeholder: 'Select Tag...',
        //     isMulti: true,
        //     getOptionValue: (option) => option.id,
        //     getOptionLabel: (option) => option.title || option.name,
        //     dropDownOptionId: 'tags',
        //     col: 6,
        //     changeType: changeType.string,
        //     disabledFunction: disableForContentWriter,
        //   },
        // ],
        // richTextInput: [
        //   { id: 'content', rawId: 'rawContent', label: 'Content', placeholder: 'Enter content...' },
        // ],
        customComponent: ViewComponents.articleForm,
      }
    case ALL_ENTITY_TYPES.metaCategoryType:
      return {
        switches: [
          { id: 'enabled', label: 'Enabled' },
          { id: 'player', label: 'Player' },
          { id: 'enabledForDailyPlay', label: 'Enabled for Daily Play' },
        ],
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
          },
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 4 },
        ],
        reactSelects: [
          {
            id: 'scope',
            label: 'Scope',
            placeholder: 'Select Scope...',
            isMulti: false,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.label,
            dropDownOptionId: 'categoryScope',
            col: 6,
            changeType: changeType.string,
            required: true,
          },
          {
            id: 'itemType',
            label: 'Item Type',
            placeholder: 'Select Item Type...',
            isMulti: true,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name || option.id,
            dropDownOptionId: 'itemTypes',
            col: 6,
            changeType: changeType.string,
          },
        ],
      }
    case ALL_ENTITY_TYPES.tag:
      return {
        switches: [{ id: 'enabled', label: 'Enabled' }],
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
          },
          { id: 'tag', label: 'Name', placeholder: 'Enter Tag Name...', required: true, col: 4 },
          {
            id: 'customShortLink',
            label: 'Custom Short Link',
            placeholder: 'Enter Short Link...',
            col: 4,
          },
        ],
        textArea: [
          {
            id: 'shortDesc',
            label: 'Short Description',
            placeholder: 'Enter Short Description...',
            row: 3,
            col: 6,
          },
          {
            id: 'welcomeMsg',
            label: 'Welcome Message',
            placeholder: 'Enter Welcome Message...',
            row: 3,
            col: 6,
          },
        ],
        reactSelects: [
          {
            id: 'categories',
            label: 'Categories',
            placeholder: 'Select Categories...',
            isMulti: true,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'categories',
            col: 6,
            changeType: changeType.string,
          },
          {
            id: 'listabilityPolicy',
            label: 'Listability Policy',
            placeholder: 'Select Policy...',
            isMulti: false,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'listabliltyPolicies',
            col: 6,
            changeType: changeType.string,
          },
          {
            id: 'type',
            label: 'Tag Type',
            placeholder: 'Select Type...',
            isMulti: false,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'tagType',
            col: 6,
            changeType: changeType.string,
            required: true,
          },
        ],
      }
    case ALL_ENTITY_TYPES.agreement:
      return {
        switches: [{ id: 'enabled', label: 'Enabled' }],
        inputs: [
          {
            id: 'title',
            label: 'Title',
            placeholder: 'Enter title...',
            required: true,
            col: 4,
          },
          {
            id: 'type',
            label: 'Agreement Type',
            placeholder: 'Enter agreement type...',
            required: true,
            col: 4,
          },
        ],
        textArea: [
          {
            id: 'shortDesc',
            label: 'Short Description',
            placeholder: 'Enter Short Description...',
            row: 3,
            col: 12,
          },
        ],
        reactSelects: [
          {
            id: 'includedCountries',
            label: 'Included Countries',
            placeholder: 'Select Included Countries...',
            isMulti: true,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'countries',
            col: 6,
            changeType: changeType.string,
            required: false,
          },
          {
            id: 'excludedCountries',
            label: 'Excluded Countries',
            placeholder: 'Select Excluded Countries...',
            isMulti: true,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'countries',
            col: 6,
            changeType: changeType.string,
            required: false,
          },
          {
            id: 'roles',
            label: 'Roles',
            placeholder: 'Select Agreement Roles...',
            isMulti: true,
            getOptionValue: (option) => option.name,
            getOptionLabel: (option) => option.title,
            dropDownOptionId: 'roles',
            col: 6,
            changeType: changeType.string,
            required: false,
          },
          {
            id: 'metaCategories',
            label: 'Categories',
            placeholder: 'Select Categories...',
            isMulti: true,
            getOptionValue: (option) => option.id,
            getOptionLabel: (option) => option.title || option.name,
            dropDownOptionId: 'metaCategories',
            col: 6,
            changeType: changeType.string,
            required: false,
            filterOptionBasedOn: filterAgreementCategories,
            optionsBasedOn: 'roles',
            viewBasedOn: 'roles',
            viewBasedOnCalculator: (roles, dropDownData) => {
              const allRoles = dropDownData?.roles
              const selectedRoles = Array.isArray(roles)
                ? roles?.map(
                    (role) =>
                      allRoles?.find((rol) => rol?.name === role) || { name: role, title: role },
                  ) || []
                : []
              return selectedRoles?.some((role) => role?.agreementRequired)
            },
          },
        ],
        dateTimeInput: [
          {
            id: 'applicableSince',
            label: 'Applicable Since',
            placeholder: 'Enter title...',
            required: true,
            col: 4,
          },
        ],
        richTextInput: [{ id: 'content', label: 'Content', placeholder: 'Enter content...' }],
      }
    case ALL_ENTITY_TYPES.role:
      return {
        customComponent: ViewComponents.roleForm,
      }
    case ALL_ENTITY_TYPES.supportStaff:
      return {
        customComponent: ViewComponents.supportStaffForm,
      }
    case 'hints':
      return {
        inputs: [
          {
            id: 'hint',
            label: 'Hint',
            placeholder: 'Enter the hint name...',
            required: true,
            col: 12,
          },
        ],
      }
    case ALL_ENTITY_TYPES.drugInfo:
      return {
        customComponent: ViewComponents.drugInfoForm,
      }
    case ALL_ENTITY_TYPES.rest:
      return {
        customComponent: ViewComponents.restComponentForm,
      }
    case ALL_ENTITY_TYPES.planPhase:
      return {
        customComponent: ViewComponents.planPhaseComponent,
      }
    case ALL_ENTITY_TYPES.activity:
      return {
        customComponent: ViewComponents.activityComponent,
      }
    case ALL_ENTITY_TYPES.diagnosis:
      return {
        inputs: [
          {
            id: 'title',
            label: 'Title',
            placeholder: 'Enter the title...',
            required: true,
            col: 12,
          },
        ],
      }
    case ALL_ENTITY_TYPES.instruction:
      return {
        customComponent: ViewComponents.instructionsForm,
      }
    case ALL_ENTITY_TYPES.healthComplaint:
      return {
        inputs: [
          {
            id: 'title',
            label: 'Title',
            placeholder: 'Enter the title...',
            required: true,
            col: 12,
          },
        ],
      }
    case ALL_ENTITY_TYPES.webinar:
      return {
        customComponent: ViewComponents.webinarForm,
      }
    case ALL_ENTITY_TYPES.oohMsg:
      return {
        customComponent: ViewComponents.oohComponent,
      }
    case ALL_ENTITY_TYPES.inAppMessages:
      return {
        customComponent: ViewComponents.inAppMessagesForm,
      }
    case ALL_ENTITY_TYPES.notifications:
      return {
        customComponent: ViewComponents.notificationsComponent,
      }
    case ALL_ENTITY_TYPES.faqScope:
      return {
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
          },
          {
            id: 'title',
            label: 'Title',
            placeholder: 'Enter the title...',
            required: true,
            col: 8,
          },
        ],
        textArea: [
          {
            id: 'description',
            label: 'Description',
            placeholder: 'Enter Description...',
            row: 1,
            col: 12,
          },
        ],
      }
    case ALL_ENTITY_TYPES.metaTabSetting:
      return {
        customComponent: ViewComponents.metaTagSettingsComponent,
      }
    case ALL_ENTITY_TYPES.displayMessageTemplates:
      return {
        customComponent: ViewComponents.displayMgsTemplateComponent,
      }
    case ALL_ENTITY_TYPES.planBundlePack:
      return {
        customComponent: ViewComponents.featureBundleComponent,
      }
    case ALL_ENTITY_TYPES.clinicBrand:
      return {
        customComponent: ViewComponents.clinicBrandComponent,
      }
    case ALL_ENTITY_TYPES.prescriptionTemplate: {
      return {
        customComponent: ViewComponents.prescriptionTemplateForm,
      }
    }
    case ALL_ENTITY_TYPES.clinicAssistant: {
      return {
        customComponent: ViewComponents.clinicAssistantForm,
      }
    }
    case ALL_ENTITY_TYPES.paymentGateways:
      return {
        customComponent: ViewComponents.paymentGatewaysForm,
      }
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      return {
        customComponent: ViewComponents.paymentGatewaySKUForm,
      }
    case ALL_ENTITY_TYPES.planGroups:
      return {
        switches: [
          { id: 'enabled', label: 'Enabled' },
          { id: 'active', label: 'Active' },
        ],
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
          },
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true },
        ],
      }
    case ALL_ENTITY_TYPES.subscriptionPlanType:
      return {
        switches: [{ id: 'enabled', label: 'Enabled' }],
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
          },
          { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true },
        ],
      }
    case ALL_ENTITY_TYPES.clinicChamber:
      return {
        customComponent: ViewComponents.clinicChamberForm,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.courseCmsFieldType:
      return {
        switches: [{ id: 'enabled', label: 'Enabled' }],
        inputs: [
          {
            id: 'id',
            label: 'Object Field Id',
            placeholder: 'Enter Field Id...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This field already exists or is empty.',
          },
          {
            id: 'title',
            label: 'Field Name',
            placeholder: 'Enter Field Name...',
            required: true,
            col: 4,
          },
        ],
        // reactSelects: [
        //   {
        //     id: 'objectFieldType',
        //     label: 'Object Field Type',
        //     placeholder: 'Select ...',
        //     isMulti: false,
        //     getOptionValue: (option) => option.id,
        //     getOptionLabel: (option) => option.title || option.name,
        //     dropDownOptionId: 'fieldTypes',
        //     col: 6,
        //     changeType: changeType.string,
        //     required: true,
        //   },
        // ],
      }
    case ALL_ENTITY_TYPES.users:
      return {
        customComponent: ViewComponents.usersForm,
      }
    case ALL_ENTITY_TYPES.segment:
      return {
        customComponent: ViewComponents.segmentsForm,
      }
    case ALL_ENTITY_TYPES.paywalls:
      return {
        customComponent: ViewComponents.paywallsForm,
      }
    case ALL_ENTITY_TYPES.backgroundMusic:
      return {
        customComponent: ViewComponents.backgroundMusicComponent,
      }
    case ALL_ENTITY_TYPES.medicalBoardMember:
      return {
        customComponent: ViewComponents.medicalBoardFormComponent,
      }
    case ALL_ENTITY_TYPES.guest:
      return {
        customComponent: ViewComponents.guestList,
      }
    case ALL_ENTITY_TYPES.supportUser:
      return {
        customComponent: ViewComponents.supportUserForm,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.sesTemplate:
      return {
        customComponent: ViewComponents.sesTemplateForm,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.investigation:
      return {
        customComponent: ViewComponents.investigationsForm,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.investigationSample:
      return {
        switches: [{ id: 'enabled', label: 'Enabled' }],
        inputs: [
          {
            id: 'id',
            label: 'ID',
            placeholder: 'Enter ID...',
            required: true,
            col: 4,
            invalid: validateID,
            feedbackInvalid: 'This id already exists or is empty.',
          },
          { id: 'title', label: 'Title', placeholder: 'Enter sample title...' },
        ],
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceDuration:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceFrequency:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceNote:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceTiming:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceDosage:
      return {
        customComponent: ViewComponents.drugAdviceItemsForm,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.bookedEvent:
      return {
        customComponent: ViewComponents.bookedEventsForm,
      }

    default:
      return undefined
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.feeModel:
      return {
        customComponent: ViewComponents.feeModelForm,
      }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.entityFeeModel:
      return {
        customComponent: ViewComponents.entityFeeModelForm,
      }
  }
}

function disableIdFieldIfMade(formData, initialData) {
  if (!initialData?.id) {
    return false
  } else {
    return true
  }
}

function filterAgreementCategories(option, basedOnValue, dropDownData) {
  console.log({ option, basedOnValue, dropDownData })
  const allRoles = dropDownData?.roles
  const selectedRoles = Array.isArray(basedOnValue)
    ? basedOnValue?.map(
        (role) => allRoles?.find((rol) => rol?.name === role) || { name: role, title: role },
      ) || []
    : []
  const toReturn = selectedRoles.some((role) => {
    return role?.metaCategoryTypes?.includes(option?.categoryType) || false
  })
  return toReturn
}

export const entityGalleryFilters = {
  subtitle: { field: 'type', filterFunction: filterData },
}
export const entityPageSkip = {
  subtitle: {
    [IMAGE_GALLERY_PAGES.localUpload]: IMAGE_GALLERY_PAGES.imageForm,
  },
  // icon:
}
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////

export function formatUploadObject(imageEntityType, id, type) {
  switch (imageEntityType) {
    case 'images':
      return { imageId: id, type: type }
    case 'avatar':
      return { fileId: id, type: null }
    default:
      return { fileId: id, type: type }
  }
}

///formatUploadObject IS ONLY FOR IMAGE TYPE COMPONENT, AND SHOULD BE REMOVED. TODO: REMOVE THIS FUNCTION, you might be looking for transformUploadObject

export function saveFunctionArgumentsChanger(url, apiParam) {
  switch (apiParam) {
    case ALL_ENTITY_TYPES.agreement:
      return {
        url: url,
        params: { entityType: apiParam },
      }
    default:
      return {
        url: `${url}/${apiParam}`,
        params: {},
      }
  }
}

////For Excel download//////////////////

export const excelDownloadUtils = {
  quote: {
    beforeDownloadProcessing: getAndAttachReflectionToQuote,
  },
}

export const pageToRender = {
  images: { new: IMAGE_GALLERY_PAGES.selectSize, edit: IMAGE_GALLERY_PAGES.imageForm },
  icon: { new: IMAGE_GALLERY_PAGES.selectSize, edit: IMAGE_GALLERY_PAGES.selectSize },
  fallback: { new: IMAGE_GALLERY_PAGES.localUpload, edit: IMAGE_GALLERY_PAGES.imageForm },
}

export const editActionLabelChanger = {}

///FOR RICH TEXT EDITORS

export const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    // 'fontFamily',
    'list',
    // 'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'remove',
    'history',
  ],
}

///For NAVIGATION

export const navDetails = {
  'mental-state': {
    valid: () => window.location.href.split('/').some((route) => route === 'user-info'),
  },
  article: {
    valid: () => window.location.href.split('/').some((route) => route === 'blogs'),
  },
  'meta-category-type': {
    valid: () => window.location.href.split('/').some((route) => route === 'category-type'),
  },
}

////////////////////////////////////////////////////////////////
// FOR COLUMNS

export function customHeaderComponents(apiParam, urlApiParam, properties) {
  const {
    handleTabChange,
    handleShowReorder,
    filters,
    setFilters,
    handleAdd,
    debounceSearch,
    debounceSetFilter,
    clearFilters,
    filtersAnchorEl,
    setFiltersAnchorEl,
    closeFiltersPopover,
    navigate,
    dropDownData,
    rStore,
    setPopupActionsStates,
    listingView,
    setListingView,
    hideTableFilters,
    setConfirmationModalProps,
    routeParams,
    hideAddEntityButton,
    hideFilterButton,
    openCsvDialog,
    hideCsvSaveButton,
  } = properties

  var toReturn
  var headerActionButtons
  var showAddButton = true

  console.log({ filters })

  switch (apiParam) {
    case ALL_ENTITY_TYPES.article:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="type"
              value={filters?.type}
              options={ARTICLE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select an article type..."
              label="Article Type"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="domainIds"
              value={filters?.domainIds}
              options={rStore.tags?.filter((tag) => tag.type === TAG_TYPE.domain)}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select article domains..."
              label="Domains"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.activity:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="metaCategoryId"
              value={filters?.metaCategoryId}
              options={rStore?.categories}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select some categories..."
              label="Category"
              onChangeEvent={setFilters}
              isClearable={true}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.activityPlan:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="status"
              value={filters?.status}
              options={DRAFT_PUBLISH_OPTIONS}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select the status of the activity plan..."
              label="Status"
              isClearable={true}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.assessment:
      toReturn = (
        <>
          <div style={{ width: '230px' }}>
            <CustomReactSelect
              isMulti
              id="tags"
              value={filters?.tags}
              options={rStore?.tags.filter((tag) =>
                tag?.categories?.some((category) => category === 'journal'),
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a tag..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="subscription"
              value={filters?.subscription}
              options={dropDownData?.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a subscription..."
              label="Subscription"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '230px' }}>
            <CustomReactSelect
              isMulti
              id="assessmentDomainList"
              value={filters?.assessmentDomainList}
              options={rStore?.tags.filter((tag) => tag?.type === 'domain')}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a domain..."
              label="Assessment Domains"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.badge:
      toReturn = (
        <CoolerTabs
          value={StatsTabsToApiParamsMapper[apiParam]}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="white"
          sx={{ selected: { backgroundColor: 'white' } }}
        >
          <Tab label="User Badges" />
          <Tab label="User Stats" />
        </CoolerTabs>
      )
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.bookedEvent:
      toReturn = (
        <BookedEventFilters dropDownData={dropDownData} filters={filters} setFilters={setFilters} />
      )
      break
    case ALL_ENTITY_TYPES.clinic:
      toReturn = (
        <>
          {/* <div style={{ width: '200px' }}>
              <CustomReactSelect
                id="kycStatus"
                value={filters?.kycStatus}
                options={customFilterOptions?.kycStatus}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.label || option?.name}
                placeholder="Select a status"
                label="KYC Status"
                onChangeEvent={setFilters}
                selectType={selectTypes.string}
              />
            </div> */}
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="availableServices"
              value={filters?.availableServices}
              options={AVAILABLE_SERVICES_OPTIONS}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a service"
              label="Available Services"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="doctors.id"
              value={filters?.['doctors.id']}
              options={dropDownData?.doctors || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a doctor"
              label="Doctors"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="therapists.id"
              value={filters?.['therapists.id']}
              options={dropDownData?.therapists || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a therapist"
              label="Therapists"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="clinicBrandId"
              value={filters?.clinicBrandId}
              options={dropDownData?.clinicBrands || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.brandName}
              placeholder="Select a clinic brand"
              label="Clinic Brand"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px', display: 'flex', alignItems: 'flex-end' }}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  id="cloudClinic"
                  checked={!!filters?.cloudClinic || false}
                  onChange={setFilters}
                />
              }
              label="Cloud Clinic: "
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.userStats:
      toReturn = (
        <CoolerTabs
          value={StatsTabsToApiParamsMapper[apiParam]}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="white"
          sx={{ selected: { backgroundColor: 'white' } }}
        >
          <Tab label="User Badges" />
          <Tab label="User Stats" />
        </CoolerTabs>
      )
      break
    case ALL_ENTITY_TYPES.mentalState:
      toReturn = (
        <>
          <div style={{ minWidth: '300px' }}>
            <CustomReactSelect
              id="type"
              value={filters?.type}
              options={rStore?.mentalStateTypes}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a mental state type..."
              label="Type"
              isClearable={true}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div>
            <Button color="secondary" onClick={handleShowReorder}>
              Re-Order States
            </Button>
          </div>
          <div></div>
        </>
      )
      break
    case ALL_ENTITY_TYPES?.mentor:
      toReturn = (
        <>
          <div>
            <CFormInput
              id="city"
              placeholder="City..."
              defaultValue={filters?.city}
              onChange={debounceSearch}
              label="City"
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="country"
              value={filters?.country}
              options={rStore?.countries}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a country..."
              label="Countries"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="languages"
              isMulti
              value={filters?.languages}
              options={rStore?.languages}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="kycStatus"
              value={filters?.kycStatus}
              options={customFilterOptions?.kycStatus}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.label || option?.name}
              placeholder="Select a status"
              label="KYC Status"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="outlined" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.appListable === true}
                onClick={() => setFilters({ target: { id: 'appListable', value: true } })}
              >
                Listable In App Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.appListable === false}
                onClick={() => setFilters({ target: { id: 'appListable', value: false } })}
              >
                Not Listable In App Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.appListable === null || filters?.appListable === undefined}
                onClick={() => setFilters({ target: { id: 'appListable', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="contained" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.recorded === true}
                onClick={() => setFilters({ target: { id: 'recorded', value: true } })}
              >
                Publishable Content Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.recorded === false}
                onClick={() => setFilters({ target: { id: 'recorded', value: false } })}
              >
                Don&apos;t Show Publishable Content
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.recorded === null || filters?.recorded === undefined}
                onClick={() => setFilters({ target: { id: 'recorded', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="contained" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.groupLive === true}
                onClick={() => setFilters({ target: { id: 'groupLive', value: true } })}
              >
                Show Group Sessions Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.groupLive === false}
                onClick={() => setFilters({ target: { id: 'groupLive', value: false } })}
              >
                Don&apos;t Show Group Sessions
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.groupLive === null || filters?.groupLive === undefined}
                onClick={() => setFilters({ target: { id: 'groupLive', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
          <div style={threeWayButtonStyles}>
            <CButtonGroup variant="contained" size="sm">
              <CButton
                color={'secondary'}
                active={filters?.live === true}
                onClick={() => setFilters({ target: { id: 'live', value: true } })}
              >
                1 - 1 Sessions Only
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.live === false}
                onClick={() => setFilters({ target: { id: 'live', value: false } })}
              >
                Don&apos;t Show 1 - 1 Sessions
              </CButton>
              <CButton
                color={'secondary'}
                active={filters?.live === null || filters?.live === undefined}
                onClick={() => setFilters({ target: { id: 'live', value: null } })}
              >
                Show All
              </CButton>
            </CButtonGroup>
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.journal:
      toReturn = (
        <>
          <div style={{ width: '230px' }}>
            <CustomReactSelect
              isMulti
              id="tags"
              value={filters?.tags}
              options={rStore?.tags.filter((tag) =>
                tag?.categories?.some((category) => category === 'journal'),
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a tag..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="subscription"
              value={filters?.subscription}
              options={dropDownData?.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title || option?.name}
              placeholder="Select a subscription..."
              label="Subscription"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.oohMsg:
      toReturn = (
        <>
          {!hideTableFilters?.includes('clinicId') && (
            <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="clinicId"
                value={filters?.clinicId}
                options={dropDownData?.clinics?.sort((a, b) => a.title?.localeCompare(b.title))}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.title}
                placeholder="Select a clinic"
                label="Clinic"
                onChangeEvent={setFilters}
                isClearable
                selectType={selectTypes.string}
              />
            </div>
          )}
          {!hideTableFilters?.includes('mentorId') && (
            <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="mentorId"
                value={filters?.mentorId}
                options={dropDownData?.mentors?.sort((a, b) => a.title?.localeCompare(b.title))}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.title}
                placeholder="Select a mentor"
                label="Mentor"
                isClearable
                onChangeEvent={setFilters}
                selectType={selectTypes.string}
              />
            </div>
          )}
          {!hideTableFilters?.includes('clinicBrandId') && (
            <div style={{ width: '300px' }}>
              <CustomReactSelect
                id="clinicBrandId"
                value={filters?.clinicBrandId}
                options={dropDownData?.clinicBrands?.sort((a, b) =>
                  a.title?.localeCompare(b.title),
                )}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option?.brandName || option?.title}
                placeholder="Select a clinic brand..."
                label="Clinic Brand"
                onChangeEvent={setFilters}
                isClearable
                selectType={selectTypes.string}
              />
            </div>
          )}
        </>
      )
      break
    case ALL_ENTITY_TYPES.metaCategoryType:
      toReturn = (
        <>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              id="scope"
              value={filters?.scope}
              options={META_CATEGORY_SCOPE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a scope"
              label="Scope"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '200px' }}>
            <CustomReactSelect
              isMulti
              id="itemType"
              value={filters?.itemType}
              options={dropDownData.itemTypes}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a itemType"
              label="Item Types"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.planBundlePack:
      toReturn = (
        <>
          <div style={{ width: '200px' }}>
            <FormControlLabel
              control={<Switch id="recurring" checked={filters.recurring} onChange={setFilters} />}
              label="Recurring"
            />
          </div>
          {/* <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="audience"
              label="Filter Audience"
              placeholder="Select filter audience..."
              value={filters.audience}
              options={SUBSCRIPTION_AUDIENCE_TYPES}
              onChangeEvent={setFilters}
              getOptionLabel={(optn) => optn.title}
              getOptionValue={(optn) => optn.id}
              selectType={selectTypes.string}
            />
          </div> */}
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="planTypeFilter"
              label="Plan Types"
              placeholder="Enter Plan Types..."
              value={filters?.planType}
              options={SUBSCRIPTION_TYPES_LAYMAN}
              getOptionLabel={(optn) => optn.title}
              getOptionValue={(optn) => optn.id}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          {/* <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="planGroups"
              label="Plan Groups"
              placeholder="Select Plan Groups..."
              value={filters?.planGroups}
              options={dropDownData?.allPlanGroups}
              onChangeEvent={setFilters}
              getOptionLabel={(optn) => optn.name}
              getOptionValue={(optn) => optn.id}
              selectType={selectTypes.string}
            />
          </div> */}
        </>
      )
      break
    case ALL_ENTITY_TYPES.onlineCourse:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="languages"
              isMulti
              value={filters?.languages}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="tag"
              value={filters?.tag}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="createdFor"
              value={filters?.createdFor}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          {/* <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="status"
              value={filters?.status}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div> */}
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="subscription"
              value={filters?.subscription}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseType"
              value={filters?.courseType}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="autoPlay"
              value={filters?.autoPlay}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="listabilityPolicy"
              value={filters?.listabilityPolicy}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="type"
              value={filters?.type}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="reqToUnpublish"
              value={filters?.reqToUnpublish}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.courseDraft:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.languages"
              isMulti
              value={filters?.['onlineCourse.languages']}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.tag"
              value={filters?.['onlineCourse.tag']}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.createdFor"
              value={filters?.['onlineCourse.createdFor']}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.status"
              value={filters?.['onlineCourse.status']}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.subscription"
              value={filters?.['onlineCourse.subscription']}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.courseType"
              value={filters?.['onlineCourse.courseType']}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.autoPlay"
              value={filters?.['onlineCourse.autoPlay']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.listabilityPolicy"
              value={filters?.['onlineCourse.listabilityPolicy']}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.type"
              value={filters?.['onlineCourse.type']}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.reqToUnpublish"
              value={filters?.['onlineCourse.reqToUnpublish']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.investigation:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isClearable
              id="investigationType"
              label="Investigation Type"
              placeholder="Enter investigation type..."
              value={filters?.investigationType}
              options={INVESTIGATION_TYPES_ARRAY}
              getOptionValue={(optn) => optn.id}
              getOptionLabel={(optn) => optn.title}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isClearable
              id="type"
              label="Test type"
              placeholder="Enter test type..."
              value={filters?.type}
              options={INVESTIGATION_TEST_TYPES_ARRAY}
              getOptionValue={(optn) => optn.id}
              getOptionLabel={(optn) => optn.title}
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '100%' }}>
            <AutomaticListAddingComponent
              isMulti
              id="units"
              label="Units"
              value={filters?.units}
              onChange={setFilters}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.unPublishRequest:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <FormControlLabel
              labelPlacement="start"
              sx={{ alignSelf: 'flex-end', margin: '0 !important' }}
              control={
                <Switch
                  checked={filters?.status === null ? true : false}
                  onChange={(e) => {
                    setFilters({
                      target: { id: 'status', value: e.target.checked ? null : 'pending' },
                    })
                  }}
                />
              }
              label={'Show all requests?'}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.languages"
              isMulti
              value={filters?.['onlineCourse.languages']}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.tag"
              value={filters?.['onlineCourse.tag']}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.createdFor"
              value={filters?.['onlineCourse.createdFor']}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.status"
              value={filters?.['onlineCourse.status']}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.subscription"
              value={filters?.['onlineCourse.subscription']}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.courseType"
              value={filters?.['onlineCourse.courseType']}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.autoPlay"
              value={filters?.['onlineCourse.autoPlay']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="onlineCourse.listabilityPolicy"
              value={filters?.['onlineCourse.listabilityPolicy']}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.type"
              value={filters?.['onlineCourse.type']}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="onlineCourse.reqToUnpublish"
              value={filters?.['onlineCourse.reqToUnpublish']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.approvalRequest:
      toReturn = (
        <>
          <div style={{ width: '300px' }}>
            <FormControlLabel
              labelPlacement="start"
              sx={{ alignSelf: 'flex-end', margin: '0 !important' }}
              control={
                <Switch
                  checked={filters?.status === null ? true : false}
                  onChange={(e) => {
                    setFilters({
                      target: { id: 'status', value: e.target.checked ? null : 'pending' },
                    })
                  }}
                />
              }
              label={'Show all requests?'}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.languages"
              isMulti
              value={filters?.['courseDraft.onlineCourse.languages']}
              options={rStore?.languages}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.languageName}
              placeholder="Select a language..."
              label="Languages"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="courseDraft.onlineCourse.tag"
              value={filters?.['courseDraft.onlineCourse.tag']}
              options={rStore.tags}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select tags..."
              label="Tags"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.createdFor"
              value={filters?.['courseDraft.onlineCourse.createdFor']}
              options={dropDownData?.mentors
                ?.slice()
                ?.filter((mentor) =>
                  mentor?.contentCategories?.includes(routeParams?.courseCategory),
                )
                ?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select teachers name..."
              label="Teacher"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.status"
              value={filters?.['courseDraft.onlineCourse.status']}
              options={ONLINE_COURSE_STATUSES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select status..."
              label="Status"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.subscription"
              value={filters?.['courseDraft.onlineCourse.subscription']}
              options={dropDownData.allPlanLevels}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a subscription level..."
              label="Subscription"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.courseType"
              value={filters?.['courseDraft.onlineCourse.courseType']}
              options={ONLINE_COURSE_MEDIA_TYPES_ARRAY}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a session type..."
              label="Session Type"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.autoPlay"
              value={filters?.['courseDraft.onlineCourse.autoPlay']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Autoplay"
              isClearable
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              isMulti
              id="courseDraft.onlineCourse.listabilityPolicy"
              value={filters?.['courseDraft.onlineCourse.listabilityPolicy']}
              options={dropDownData?.listabliltyPolicies}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Select a listabilityPolicy..."
              label="Listability Policy"
              onChangeEvent={setFilters}
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.type"
              value={filters?.['courseDraft.onlineCourse.type']}
              options={ONLINE_COURSE_TYPES}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select a course type..."
              label="Course Type"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
          <div style={{ width: '300px' }}>
            <CustomReactSelect
              id="courseDraft.onlineCourse.reqToUnpublish"
              value={filters?.['courseDraft.onlineCourse.reqToUnpublish']}
              options={ONLINE_COURSE_AUTOPLAY_TYPE}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option?.title}
              placeholder="Select..."
              label="Request To Unpublish"
              onChangeEvent={setFilters}
              isClearable
              selectType={selectTypes.string}
            />
          </div>
        </>
      )
      break
    case ALL_ENTITY_TYPES.cacheEvict:
      toReturn = (
        <CacheEvictHeaderFirstLayer setConfirmationModalProps={setConfirmationModalProps} />
      )
      break
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      toReturn = (
        <>
          <PaymentGatewaySKUHeaderFirstLayer
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.paymentGateways:
      toReturn = (
        <>
          <PaymentGatewayHeaderFirstLayer
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.users:
      toReturn = (
        <>
          <UsersHeaderComponentsFirstLayer
            rStore={rStore}
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    case ALL_ENTITY_TYPES.payoutDistributionReports:
      toReturn = (
        <>
          <PayoutDistributionReportsHeaderFirstLayer
            rStore={rStore}
            dropDownData={dropDownData}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )
      break
    default:
      toReturn = null
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES.oohMsg:
      headerActionButtons = (
        <div>
          <Tooltip title="Re-Order OOH Messages">
            <IconButton
              color="secondary"
              onClick={() =>
                setPopupActionsStates((prev) => ({
                  ...prev,
                  open: true,
                  type: POPUP_ACTION_STATES_TYPES.reorderOohMsgs,
                }))
              }
            >
              <SwapVert />
            </IconButton>
          </Tooltip>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.mentalStateType:
      headerActionButtons = (
        <Tooltip title={'Mental States'}>
          <IconButton
            onClick={() => {
              navigate(ALL_ENTITY_TYPES.mentalState)
            }}
          >
            <Settings />
          </IconButton>
        </Tooltip>
      )
      break
    case ALL_ENTITY_TYPES.activityPlan:
      headerActionButtons = (
        <>
          <Tooltip title="Activity">
            <IconButton
              onClick={() => {
                navigate('activity')
              }}
            >
              <FitnessCenter />
            </IconButton>
          </Tooltip>
          <Tooltip title="Plan Phases">
            <IconButton
              onClick={() => {
                navigate('plan-phase')
              }}
            >
              <DateRange />
            </IconButton>
          </Tooltip>
        </>
      )
      break
    case ALL_ENTITY_TYPES.displayMessageTemplates:
      headerActionButtons = (
        <ToggleButtonGroup
          color="standard"
          value={listingView}
          exclusive
          onChange={(e, val) => setListingView(val)}
        >
          <ToggleButton value={LISTING_VIEWS.list}>List</ToggleButton>
          <ToggleButton value={LISTING_VIEWS.grid}>Grid</ToggleButton>
        </ToggleButtonGroup>
      )
      break
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      headerActionButtons = (
        <Tooltip title="Payment Gateways">
          <IconButton
            onClick={() => {
              navigate(ALL_ENTITY_TYPES.paymentGateways)
            }}
          >
            <PointOfSale />
          </IconButton>
        </Tooltip>
      )
      break
    case ALL_ENTITY_TYPES.segment:
      headerActionButtons = (
        <div style={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            onClick={() =>
              setPopupActionsStates((prev) => ({
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.segmentTester,
              }))
            }
            sx={{ flexShrink: 0 }}
          >
            Segment Tester
          </Button>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.drugAdviceDuration:
    case ALL_ENTITY_TYPES.drugAdviceFrequency:
    case ALL_ENTITY_TYPES.drugAdviceNote:
    case ALL_ENTITY_TYPES.drugAdviceDosage:
    case ALL_ENTITY_TYPES.drugAdviceTiming:
      headerActionButtons = (
        <div style={{ flexShrink: 0 }}>
          <Button
            color="secondary"
            onClick={() =>
              setPopupActionsStates((prev) => ({
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.reorderDrugAdviceAvailableToAll,
              }))
            }
            sx={{ flexShrink: 0 }}
          >
            Reorder available to all
          </Button>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.clinicAssistantUser:
      headerActionButtons = (
        <div style={{ flexShrink: 0 }}>
          <IconButton
            onClick={() =>
              setPopupActionsStates((prev) => ({
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.clinicAssistantAddition,
              }))
            }
            sx={{ flexShrink: 0 }}
          >
            <Add />
          </IconButton>
        </div>
      )
      break
    case ALL_ENTITY_TYPES.investigation:
      headerActionButtons = (
        <>
          <div style={{ flexShrink: 0 }}>
            <IconButton
              onClick={() =>
                setPopupActionsStates((prev) => ({
                  ...prev,
                  open: true,
                  type: POPUP_ACTION_STATES_TYPES.multiUploadInvestigations,
                }))
              }
              sx={{ flexShrink: 0 }}
            >
              <PlusOne />
            </IconButton>
          </div>
          <div style={{ flexShrink: 0 }}>
            <Tooltip title="Sample Types">
              <IconButton
                onClick={() => navigate(ALL_ENTITY_TYPES_FROM_CONSTANTS.investigationSample)}
                sx={{ flexShrink: 0 }}
              >
                <Science />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )
      break
    default:
      headerActionButtons = <></>
  }

  switch (apiParam) {
    case ALL_ENTITY_TYPES.clinic:
      showAddButton = urlApiParam === 'clinic-prospects' ? true : false
      break
    case ALL_ENTITY_TYPES.approvalRequest:
    case ALL_ENTITY_TYPES.onlineCourse:
    case ALL_ENTITY_TYPES.unPublishRequest:
      showAddButton = false
      break

    default:
      showAddButton = apiParam === ALL_ENTITY_TYPES.article ? true : !disableForContentWriter()
  }

  const allValidFiltersLen = Object.keys(filters).filter((key) => {
    if (Array.isArray(filters?.[key])) {
      return !!filters?.[key]?.length
    }
    if (key === 'search') {
      return false
    }
    return !!filters?.[key]
  })?.length

  return (
    <>
      <div className="d-flex flex-wrap gap-2 w-100">
        <StyledListingSearchBar>
          <CFormInput
            placeholder={`Search ${apiParam}...`}
            defaultValue={filters?.search}
            onChange={debounceSearch}
            label="Search"
          />
        </StyledListingSearchBar>
        <FilterPopover
          anchorEl={filtersAnchorEl}
          open={Boolean(filtersAnchorEl)}
          onClose={closeFiltersPopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {toReturn || <></>}
        </FilterPopover>
      </div>
      {!!toReturn && !hideFilterButton && (
        <div>
          <Badge badgeContent={allValidFiltersLen} color="primary">
            <IconButton onClick={(e) => setFiltersAnchorEl(e.currentTarget)}>
              <FilterAlt />
            </IconButton>
          </Badge>
        </div>
      )}
      <Tooltip title="Reset Filters">
        <IconButton onClick={clearFilters}>
          <Close />
        </IconButton>
      </Tooltip>{' '}
      {!hideCsvSaveButton && (
        <Button color="primary" variant="contained" sx={{ flexShrink: 0 }} onClick={openCsvDialog}>
          Save Data through CSV
        </Button>
      )}
      {headerActionButtons}
      {showAddButton && !hideAddEntityButton && (
        <Tooltip title="Add">
          <IconButton
            onClick={() => {
              handleAdd()
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export function conditionalColumns(apiParam, params = {}, tempApiParam) {
  return getListOfColumnsForSmartTable(apiParam, (params = {}), tempApiParam)
}

export function customScopedColumns(apiParam, dropDownData, rStore, contextData = {}) {
  return getExtraScopedColumnsForSmartTable(apiParam, dropDownData, rStore, contextData)
}

export function customScopedActions(apiParam, extraProps) {
  const {
    onRowClick,
    onEditClick,
    handleEnableDisable,
    setConfirmationModalProps,
    setPopupActionsStates,
  } = extraProps

  var finalScopedActions = [
    {
      id: GENERIC_DEFAULT_ACTIONS.view,
      onClick: (formData) => onRowClick(formData),
      label: 'View',
      showCondition: () => true,
    },
    {
      id: GENERIC_DEFAULT_ACTIONS.edit,
      onClick: (formData) => onEditClick(formData),
      label: 'Edit',
      showCondition: () => true,
    },
    {
      id: GENERIC_DEFAULT_ACTIONS.enable_disable,
      onClick: (formData) => handleEnableDisable(formData),
      label: (item) => (!!item?.enabled ? 'Disable' : 'Enable'),
      showCondition: (item, idx) => !disableForContentWriter(),
    },
    // {
    //   onClick: (formData) =>
    //     cloneEntity(apiParam, formData, 'title', extraProps?.storeData, extraProps?.refresh),
    //   label: 'Clone',
    //   showCondition: () => true,
    // },
  ]

  switch (apiParam) {
    case ALL_ENTITY_TYPES.mentor:
      finalScopedActions = [
        ...finalScopedActions,
        {
          onClick: (formData) => approveTeacher(formData, extraProps.refresh),
          label: 'Approve',
          showCondition: getApprovedCondition,
        },
      ]
      break
    case ALL_ENTITY_TYPES.clinic:
      finalScopedActions = [
        ...finalScopedActions,
        {
          onClick: (formData) => approveClinic(formData, extraProps.refresh),
          label: 'Approve',
          showCondition: getApprovedCondition,
        },
      ]
      break
    case ALL_ENTITY_TYPES.displayMessageTemplates:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.feeModel:
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.entityFeeModel:
      finalScopedActions = [
        ...finalScopedActions,
        {
          onClick: (formData) =>
            cloneEntity(apiParam, formData, 'title', extraProps?.storeData, extraProps?.refresh),
          label: 'Clone',
          showCondition: () => true,
        },
      ]
      break
    case ALL_ENTITY_TYPES.clinicBrand:
      finalScopedActions = [
        ...finalScopedActions,
        {
          onClick: (formData) => approveClinicBrand(formData, extraProps.refresh),
          label: 'Approve',
          showCondition: getApprovedCondition,
        },
      ]
      break
    case ALL_ENTITY_TYPES.cacheEvict:
      finalScopedActions = finalScopedActions?.filter(
        (actn) => actn?.id === GENERIC_DEFAULT_ACTIONS.view,
      )
      finalScopedActions = finalScopedActions?.map((actn) =>
        actn?.id === GENERIC_DEFAULT_ACTIONS.view ? { ...actn, label: 'Evict Cache' } : actn,
      )
      break
    case ALL_ENTITY_TYPES.users:
      finalScopedActions = finalScopedActions?.filter(
        (actn) =>
          !(
            actn?.id === GENERIC_DEFAULT_ACTIONS.enable_disable ||
            actn?.id === GENERIC_DEFAULT_ACTIONS.edit
          ),
      )
      break
    case ALL_ENTITY_TYPES.courseDraft:
      finalScopedActions = finalScopedActions?.map((actn) => {
        if (actn?.id === GENERIC_DEFAULT_ACTIONS.edit) {
          return {
            ...actn,
            onClick: (item) => onEditClick(item),
            label: 'Edit',
            showCondition: (item) => {
              const viewStatus = getActionButtonViewStatusForOnlineCourse(
                item?.onlineCourse,
                'View',
              )
              return viewStatus.edit
            },
          }
        }
        if (actn?.id === GENERIC_DEFAULT_ACTIONS.view) {
          return {
            ...actn,
            onClick: (item) => onRowClick(item),
            label: 'View',
            showCondition: (item) => {
              const viewStatus = getActionButtonViewStatusForOnlineCourse(
                item?.onlineCourse,
                'View',
              )
              return viewStatus.edit
            },
          }
        }
        return actn
      })
      break
    case ALL_ENTITY_TYPES.onlineCourse:
      finalScopedActions = finalScopedActions?.map((actn) => {
        if (actn?.id === GENERIC_DEFAULT_ACTIONS.edit) {
          return {
            ...actn,
            onClick: (item) => onEditClick(item),
            label: 'Edit',
            showCondition: (item) => {
              const viewStatus = getActionButtonViewStatusForOnlineCourse(item, 'View')
              return viewStatus.edit
            },
          }
        }
        return actn
      })
      finalScopedActions = [
        ...finalScopedActions,
        {
          id: 'publish-course',
          onClick: (item) => {
            setConfirmationModalProps((prev) => ({
              ...prev,
              action: item?.status === 'approved' ? 'publish' : 're-publish',
              body:
                item?.status === 'approved'
                  ? `Do you want to publish this online course?`
                  : `Do you want to re-publish this online course?`,
              buttonColor: item?.status === 'approved' ? 'success' : 'warning',
              visibility: true,
              visibilitySetter: setConfirmationModalProps,
              onSubmitFunctions: [
                () =>
                  publishOnlineCourseApiCall({
                    courseId: item?.id,
                    afterSuccessFunction: extraProps.refresh,
                  }),
              ],
            }))
          },
          label: (item) => {
            return item?.status === 'approved' ? 'Publish Course' : 'Re-Publish Course'
          },
          showCondition: (item, idx) => {
            return item?.status === 'approved' || item?.status === 'unpublished' ? true : false
          },
        },
        {
          id: 'unpublish-course',
          onClick: (formData) => {
            setConfirmationModalProps((prev) => ({
              ...prev,
              action: 'un-publish',
              body: `Do you want to unpublish this online course?`,
              buttonColor: 'error',
              visibility: true,
              visibilitySetter: setConfirmationModalProps,
              onSubmitFunctions: [
                () =>
                  unPublishOnlineCourseApiCall({
                    courseId: formData?.id,
                    afterSuccessFunction: extraProps.refresh,
                  }),
              ],
            }))
          },
          label: (item) => {
            return 'Un-Publish Course'
          },
          showCondition: (item, idx) => {
            return item?.status === 'published' ? true : false
          },
        },
      ]
      break
    case ALL_ENTITY_TYPES.unPublishRequest:
      finalScopedActions = finalScopedActions?.filter((actn) => {
        if (actn?.id === GENERIC_DEFAULT_ACTIONS.view) {
          return true
        }
        return false
      })
      finalScopedActions = [
        ...finalScopedActions,
        // {
        //   id: 'reject-request',
        //   onClick: (formData) => {
        //     setConfirmationModalProps((prev) => ({
        //       ...prev,
        //       action: 'publish',
        //       body: `Do you want to reject this un-publishing request?`,
        //       buttonColor: 'error',
        //       visibility: true,
        //       visibilitySetter: setConfirmationModalProps,
        //       onSubmitFunctions: [
        //         () =>
        //           rejectReqToUnpublishOnlineCourseApiCall({
        //             requestId: formData?.id,
        //             courseId: formData?.contentId,
        //             afterSuccessFunction: extraProps.refresh,
        //           }),
        //       ],
        //     }))
        //   },
        //   label: (item) => {
        //     return 'Reject Un-Publishing Request'
        //   },
        //   showCondition: (item, idx) => {
        //     return item?.status === 'pending'
        //   },
        // },
        // {
        //   id: 'accept-request',
        //   onClick: (formData) => {
        //     setConfirmationModalProps((prev) => ({
        //       ...prev,
        //       action: 'un-publish',
        //       body: `Do you want to approve this un-publishing request?`,
        //       buttonColor: 'error',
        //       visibility: true,
        //       visibilitySetter: setConfirmationModalProps,
        //       onSubmitFunctions: [
        //         () =>
        //           approveReqToUnpublishOnlineCourseApiCall({
        //             requestId: formData?.id,
        //             courseId: formData?.contentId,
        //             afterSuccessFunction: extraProps.refresh,
        //           }),
        //       ],
        //     }))
        //   },
        //   label: (item) => {
        //     return 'Approve Un-Publishing Request'
        //   },
        //   showCondition: (item, idx) => {
        //     return item?.status === 'pending'
        //   },
        // },
      ]
      break

    case ALL_ENTITY_TYPES.planBundlePack:
      finalScopedActions = [
        ...finalScopedActions,
        {
          id: 'send-bundle-to-user',
          onClick: (item) => {
            setPopupActionsStates((prev) => {
              return {
                ...prev,
                open: true,
                type: POPUP_ACTION_STATES_TYPES.sendBundleToUser,
                itemInPerspective: item,
              }
            })
          },
          label: 'Send Bundle to User',
          showCondition: () => true,
        },
      ]
      break
    case ALL_ENTITY_TYPES.approvalRequest:
      finalScopedActions = finalScopedActions?.filter((actn) => {
        if (actn?.id === GENERIC_DEFAULT_ACTIONS.view) {
          return true
        }
        return false
      })
      finalScopedActions = [
        ...finalScopedActions,
        // {
        //   id: 'reject-request',
        //   onClick: (formData) => {
        //     setConfirmationModalProps((prev) => ({
        //       ...prev,
        //       action: 'publish',
        //       body: `Do you want to reject this approval request?`,
        //       buttonColor: 'error',
        //       visibility: true,
        //       visibilitySetter: setConfirmationModalProps,
        //       onSubmitFunctions: [
        //         () =>
        //           rejectReqToUnpublishOnlineCourseApiCall({
        //             requestId: formData?.id,
        //             courseId: formData?.contentId,
        //             afterSuccessFunction: extraProps.refresh,
        //           }),
        //       ],
        //     }))
        //   },
        //   label: (item) => {
        //     return 'Reject Approval Request'
        //   },
        //   showCondition: (item, idx) => {
        //     return item?.status === 'pending'
        //   },
        // },
        // {
        //   id: 'accept-request',
        //   onClick: (formData) => {
        //     setConfirmationModalProps((prev) => ({
        //       ...prev,
        //       action: 'un-publish',
        //       body: `Do you want to approve this approval request?`,
        //       buttonColor: 'error',
        //       visibility: true,
        //       visibilitySetter: setConfirmationModalProps,
        //       onSubmitFunctions: [
        //         () =>
        //           approveReqToUnpublishOnlineCourseApiCall({
        //             requestId: formData?.id,
        //             courseId: formData?.contentId,
        //             afterSuccessFunction: extraProps.refresh,
        //           }),
        //       ],
        //     }))
        //   },
        //   label: (item) => {
        //     return 'Approve Course Approval Request'
        //   },
        //   showCondition: (item, idx) => {
        //     return item?.status === 'pending'
        //   },
        // },
      ]
      break
    default:
      break
  }
  return finalScopedActions
}

export function onViewAddFunction(
  apiParam,
  dialogShowFunction,
  itemToView,
  setItemToEdit,
  navigate,
  setMode,
  modeType,
  { routeParams, location, listingItems, filters },
) {
  const itemToUse = modeType === 'Add' ? { id: 'new' } : itemToView
  const currentRoute = `${window.location.origin}${location.pathname}`

  switch (apiParam) {
    case ALL_ENTITY_TYPES.cacheEvict:
      return
    case ALL_ENTITY_TYPES.approvalRequest:
    case ALL_ENTITY_TYPES.unPublishRequest:
    case ALL_ENTITY_TYPES.onlineCourse:
      console.log({ location })
      openInNewTab(`${currentRoute}/${itemToUse?.id}?mode=${modeType}`)
      break
    case ALL_ENTITY_TYPES.courseDraft:
      openInNewTab(`${currentRoute}/${itemToUse?.id}?mode=${modeType}`)
      break

    case ALL_ENTITY_TYPES.mentor:
    case ALL_ENTITY_TYPES.clinic:
    case ALL_ENTITY_TYPES.journal:
    case ALL_ENTITY_TYPES.assessment:
    case ALL_ENTITY_TYPES.quote:
    case ALL_ENTITY_TYPES.activityPlan:
    case ALL_ENTITY_TYPES.inAppMessages:
    case ALL_ENTITY_TYPES.notifications:
    case ALL_ENTITY_TYPES.metaTabSetting:
    case ALL_ENTITY_TYPES.tab:
    case ALL_ENTITY_TYPES.users:
    case ALL_ENTITY_TYPES.planBundlePack:
    case ALL_ENTITY_TYPES.segment:
    case ALL_ENTITY_TYPES.paywalls:
    case ALL_ENTITY_TYPES.backgroundMusic:
    case ALL_ENTITY_TYPES.entityFeeModel:
    case ALL_ENTITY_TYPES.feeModel:
      navigate(`${itemToUse?.id}?mode=${modeType}`)
      break

    case ALL_ENTITY_TYPES.investigation:
      var investigationItemToEdit = itemToView
      if (modeType === 'Add') {
        setMode(modeType)
        if (!listingItems || listingItems?.length === 0) {
          investigationItemToEdit = { ...itemToView, title: filters?.search }
        }
      }
      setItemToEdit(investigationItemToEdit)
      dialogShowFunction(true)
      break
    case ALL_ENTITY_TYPES.clinicBrand:
      if (modeType === 'Add') {
        dialogShowFunction(true)
        setItemToEdit(itemToView)
        setMode(modeType)
      } else {
        navigate(`${itemToUse?.id}`)
      }
      break

    default:
      dialogShowFunction(true)
      setItemToEdit(itemToView) //this is there so that id: "new" is not being used to make the new entity
      setMode(modeType)
  }
}

////////////////////////////////////////////////////////////////

//////////For Post////////////////

/////////////For Fetch////////////////

export async function getExtraDataForFormAndListing({ apiParam, locationParams }) {
  let extraData = {}

  console.log({ locationParams })

  switch (apiParam) {
    case ALL_ENTITY_TYPES.tag:
      extraData = {
        listabliltyPolicies: {
          function: fetchFunction,
          url: SETTINGS_API_LIST.listabilityPolicy.get.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.metaCategoryType:
      extraData = {
        categoryScope: {
          function: getCategoryScopes,
          url: '',
          params: {},
        },
        itemTypes: {
          function: fetchFunction,
          url: SETTINGS_API_LIST.cardFillingMethods.get.url,
          params: {},
        },
        apiParams: {
          function: fetchFunction,
          url: SETTINGS_API_LIST.apiParams.get.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.agreement:
      extraData = {
        roles: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: 'role', size: 1000 },
        },
        metaCategories: {
          function: fetchFunction,
          url: APIs.contentCategories.url,
          params: { includeDisabled: true, includeFeatures: true },
        },
      }
      break
    case ALL_ENTITY_TYPES.supportStaff:
      extraData = {
        clinics: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: 'clinic', size: 1000 },
        },
      }
      break
    case ALL_ENTITY_TYPES.journal:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.assessment:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.rest:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.activityPlan:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
        subscriptionPlans: {
          function: fetchFunction,
          url: SubscriptionApis.allPlans.url,
          params: {},
          arg5: (data) => data?.filter((plan) => plan?.planGrpId === '1on1'),
        },
        faqCategories: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.faqCategory,
            scopeId: ALL_ENTITY_TYPES.activityPlan,
            size: 1000,
          },
          arg5: (data) => data?.filter((plan) => plan?.planGrpId === '1on1'),
        },
      }
      break
    case ALL_ENTITY_TYPES.oohMsg:
      extraData = {
        clinics: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.clinic, size: 1000, enabled: true },
        },
        clinicBrands: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.clinicBrand, size: 1000 },
        },
        mentors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 1000,
            categories: 'therapist,mental-health-expert,psychiatrist,doctor,',
          },
        },
      }
      break
    case ALL_ENTITY_TYPES.article:
      extraData = {
        faqCategories: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.faqCategory,
            size: 1000,
            scopeId: ALL_ENTITY_TYPES.article,
          },
        },
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.webinar:
      extraData = {
        subscriptionOptions: {
          function: fetchFunction,
          url: SubscriptionApis.allPlans.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.inAppMessages:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.planBundlePack:
      extraData = {
        allPlanGroups: {
          function: fetchFunction,
          url: SubscriptionApis.planGroups.url,
          params: {},
        },
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
        allPlanTypes: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.subscriptionPlanType,
            size: 1000,
          },
        },
        allSubscriptionPlans: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.consumerSubscriptionPlan, size: 1000 },
        },
      }
      break
    case ALL_ENTITY_TYPES.clinic:
      extraData = {
        therapists: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 1000,
            categories: 'therapist,mental-health-expert',
            kycStatus: 'approved',
            enabled: true,
          },
        },
        doctors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 1000,
            categories: 'psychiatrist,',
            kycStatus: 'approved',
            enabled: true,
          },
        },
        clinicBrands: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.clinicBrand,
            size: 1000,
          },
        },
      }
      break
    case ALL_ENTITY_TYPES.prescriptionTemplate:
      extraData = {
        doctors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 1000,
            categories: 'psychiatrist,doctor',
            kycStatus: 'approved',
            enabled: true,
          },
        },
        clinics: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.clinic, size: 1000 },
        },
      }
      break
    case ALL_ENTITY_TYPES.unPublishRequest:
    case ALL_ENTITY_TYPES.courseDraft:
    case ALL_ENTITY_TYPES.onlineCourse:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
        mentors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 999,
            kycStatus: 'approved',
            enabled: true,
          },
        },
        listabliltyPolicies: {
          function: fetchFunction,
          url: SETTINGS_API_LIST.listabilityPolicy.get.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      extraData = {
        allPlanGroups: {
          function: fetchFunction,
          url: SubscriptionApis.planGroups.url,
          params: {},
        },
        paymentGateways: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.paymentGateways,
          },
        },
      }
      break
    case ALL_ENTITY_TYPES.doctorUsers:
    case ALL_ENTITY_TYPES.clinicAssistantUser:
    case ALL_ENTITY_TYPES.users:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
        subscriptionPlans: {
          function: fetchFunction,
          url: SubscriptionApis.allPlans.url,
          params: {},
          arg5: (data) => data?.filter((plan) => plan?.planGrpId === '1on1'),
        },
        segments: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.segment, size: 1000, enabled: true },
        },
        acquisitionSources: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.acquisitionModes, size: 1000, enabled: true },
        },
        userTags: {
          function: fetchFunction,
          url: USERS_API_SIGNATURES_MAP.userTags.get,
          params: {},
        },
        focusArea: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.focusArea, size: 1000, enabled: true },
        },
      }
      break
    case ALL_ENTITY_TYPES.segment:
      extraData = {
        allPlanLevels: {
          function: fetchFunction,
          url: SubscriptionApis.subscriptionLevels.url,
          params: {},
        },
        allRoles: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.role,
            size: 999,
          },
        },
        onboardingTypes: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.onboardingType,
            size: 999,
          },
        },
        ageGroups: {
          function: fetchFunction,
          url: 'policy/age-groups',
          params: {},
        },
        // : {
        //   function: fetchPaginatedFunction,
        //   url: GENERIC_APIS.entity.getPaginated,
        //   params: {
        //     entity: ALL_ENTITY_TYPES.onboardingType,
        //     size: 999,
        //   },
        // },
      }
      break
    case ALL_ENTITY_TYPES.guest:
      extraData = {
        mentors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 999,
            kycStatus: 'approved',
            enabled: true,
          },
        },
      }
      break
    case ALL_ENTITY_TYPES.supportUser:
      extraData = {
        clinics: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.clinic,
            size: 999,
            kycStatus: 'approved',
            enabled: true,
          },
        },
      }
      break
    case ALL_ENTITY_TYPES.role:
      extraData = {
        ...extraData,
        allRoles: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.role,
            size: 999,
            enabled: true,
          },
        },
        allEntityParams: {
          function: fetchFunction,
          url: SETTINGS_API_LIST.apiParams.get.url,
          params: {},
        },
      }
      break
    case ALL_ENTITY_TYPES.investigation:
      extraData = {
        investigationSample: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.investigationSample, size: 1000 },
        },
      }
      break
    case ALL_ENTITY_TYPES.bookedEvent:
      extraData = {
        mentors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 999,
            kycStatus: 'approved',
            enabled: true,
          },
        },
        clinicBrands: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.clinicBrand,
            size: 1000,
          },
        },
        clinics: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: { entityType: ALL_ENTITY_TYPES.clinic, size: 1000, enabled: true },
        },
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.entityFeeModel:
      extraData = {
        mentors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 999,
            kycStatus: 'approved',
            enabled: true,
          },
        },
        allModels: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.feeModel,
            size: 1000,
          },
        },
        clinicBrands: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.clinicBrand,
            size: 1000,
          },
        },
        // clinics: {
        //   function: fetchPaginatedFunction,
        //   url: GENERIC_APIS.entity.getPaginated,
        //   params: { entityType: ALL_ENTITY_TYPES.clinic, size: 1000, enabled: true },
        // },
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.payoutDistributionReports:
      extraData = {
        mentors: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.mentor,
            size: 999,
            kycStatus: 'approved',
            enabled: true,
          },
        },
        allModels: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.feeModel,
            size: 1000,
          },
        },
        allEntityModels: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.entityFeeModel,
            size: 1000,
          },
        },
        clinicBrands: {
          function: fetchPaginatedFunction,
          url: GENERIC_APIS.entity.getPaginated,
          params: {
            entityType: ALL_ENTITY_TYPES.clinicBrand,
            size: 1000,
          },
        },
        // clinics: {
        //   function: fetchPaginatedFunction,
        //   url: GENERIC_APIS.entity.getPaginated,
        //   params: { entityType: ALL_ENTITY_TYPES.clinic, size: 1000, enabled: true },
        // },
      }
      break

    default:
      extraData = null
      break
  }

  if (locationParams?.id) {
    switch (apiParam) {
      case ALL_ENTITY_TYPES.courseDraft:
      case ALL_ENTITY_TYPES.unPublishRequest:
      case ALL_ENTITY_TYPES.onlineCourse:
        extraData = {
          ...extraData,
          sectionsAndFields: {
            function: fetchFunction,
            url: COURSE_CMS_FORM_FIELDS_SECTIONS_API_SIGNATURE_MAP.sectionsAndFieldsForACategory
              .get,
            params: {
              'course-category-id': locationParams?.courseCategory,
            },
          },
        }
        break
      case ALL_ENTITY_TYPES.segment:
        extraData = {
          ...extraData,
          onboardingPolicies: {
            function: fetchPaginatedFunction,
            url: GENERIC_APIS.entity.getPaginated,
            params: { entityType: ALL_ENTITY_TYPES.onboardingPolicy, size: 1000 },
            arg5: true,
          },
          tabAndTilePolicies: {
            function: fetchPaginatedFunction,
            url: GENERIC_APIS.entity.getPaginated,
            params: { entityType: ALL_ENTITY_TYPES.metaTabSetting, size: 1000 },
            arg5: true,
          },
          focusAreaPolicies: {
            function: fetchFunction,
            url: POLICIES_API_SIGNATURE_MAP.focusAreaPolicies.get,
            arg5: true,
          },
          acquisitionPolicies: {
            function: fetchFunction,
            url: POLICIES_API_SIGNATURE_MAP.acquisitionPolicies.get,
            arg5: true,
          },
          categoryPolicies: {
            function: fetchFunction,
            url: POLICIES_API_SIGNATURE_MAP.categoryPolicies.get,
            arg5: true,
          },
          prewallPolicies: {
            function: fetchFunction,
            url: POLICIES_API_SIGNATURE_MAP.prewallPolicies.get,
            arg5: true,
          },
          emailPolicies: {
            function: fetchFunction,
            url: POLICIES_API_SIGNATURE_MAP.emailPolicies.get,
            arg5: true,
          },
          trialPlans: {
            function: fetchFunction,
            url: SubscriptionApis.allPlans.url,
            params: {},
            arg5: (data) =>
              data?.filter((plan) => plan?.subscriptionType === SUBSCRIPTION_TYPES_LAYMAN?.[1]?.id),
          },
          ageGroups: {
            function: fetchFunction,
            url: POLICIES_API_SIGNATURE_MAP.ageGroups.get,
            arg5: true,
          },
        }
        break
      case ALL_ENTITY_TYPES.guest:
        extraData = {
          mentors: {
            function: fetchPaginatedFunction,
            url: GENERIC_APIS.entity.getPaginated,
            params: {
              entityType: ALL_ENTITY_TYPES.mentor,
              size: 999,
              kycStatus: 'approved',
              enabled: true,
            },
          },
        }
        break
      case ALL_ENTITY_TYPES.entityFeeModel:
        extraData = {
          ...extraData,
          allModels: null,
        }
        break
      default:
        break
    }
  }

  return extraData
}

export function getFullSaveObject(apiParam, allItems, totalNumberOfItems, appSettingsStore) {
  const todayDate = new Date()
  const todayFormatted = moment.utc(todayDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
  var objectToReturn = {}

  console.log({ apiParam })

  switch (apiParam) {
    case 'avatar':
      objectToReturn = AVATAR_IMAGE_OBJECT
      break
    case 'images':
      objectToReturn = META_IMAGES_OBJECT
      break
    case 'subtitle':
      objectToReturn = SUBTITLE_OBJECT
      break
    case 'icon':
      objectToReturn = ICONS_OBJECT
      break
    case 'badge':
      objectToReturn = USER_BADGE_OBJECT
      break
    case 'user-stats':
      objectToReturn = { ...USER_STATS_OBJECT, sectionId: ObjectId() }
      break
    case 'mental-state':
      objectToReturn = { ...USER_MENTAL_STATE_OBJECT, orderId: (allItems?.length || 0) + 1 }
      break
    case 'mental-state-type':
      objectToReturn = USER_MENTAL_STATE_TYPE_OBJECT
      break
    case 'meta-category-type':
      objectToReturn = META_CATEGORY_TYPE_OBJECT
      break
    case 'article':
      objectToReturn = BLOG_OBJECT
      break
    case 'tag':
      objectToReturn = META_TAG_OBJECT
      break
    case 'mentor':
      objectToReturn = TEACHER_OBJECT
      break
    case 'clinic':
      objectToReturn = CLINIC_OBJECT
      break
    case 'agreement':
      objectToReturn = { ...AGREEMENT_OBJECT, applicableSince: todayFormatted }
      break
    case 'support-staff':
      objectToReturn = { ...SUPPORT_STAFF_OBJECT, startDate: todayFormatted }
      break
    case 'role':
      objectToReturn = { ...ROLE_OBJECT, applicableSince: todayFormatted }
      break
    case 'assessment':
      objectToReturn = ASSESSMENT_OBJECT
      break
    case 'journal':
      objectToReturn = JOURNAL_OBJECT
      break
    case 'quote':
      objectToReturn = QUOTE_OBJECT
      break
    case 'hint':
      objectToReturn = { ...HINT_OBJECT }
      break
    case 'drug-info':
      objectToReturn = DRUG_INFO_OBJECT
      break
    case 'diagnosis':
      objectToReturn = DIAGNOSIS_OBJECT
      break
    case 'health-complaint':
      objectToReturn = HEALTH_COMPLAINT_OBJECT
      break
    case 'rest':
      objectToReturn = REST_OBEJCT
      break
    case ALL_ENTITY_TYPES.planPhase:
      objectToReturn = { ...PHASE_OBJECT }
      break
    case ALL_ENTITY_TYPES.activity:
      objectToReturn = ACTIVITY_OBJECT
      break
    case ALL_ENTITY_TYPES.activityPlan:
      objectToReturn = ACTIVITY_PLAN_OBJECT
      break
    case ALL_ENTITY_TYPES.webinar:
      objectToReturn = WEBINAR_OBJECT
      break
    case ALL_ENTITY_TYPES.oohMsg:
      objectToReturn = { ...OOH_MESSAGES_OBJECT, orderId: totalNumberOfItems }
      break
    case ALL_ENTITY_TYPES.notifications:
      objectToReturn = { ...NOTIFICATION_OBJECT }
      break
    case ALL_ENTITY_TYPES.faqScope:
      objectToReturn = { ...FAQ_SCOPE_OBJECT }
      break
    case ALL_ENTITY_TYPES.inAppMessages:
      objectToReturn = IN_APP_MSG_OBJECT
      break
    case ALL_ENTITY_TYPES.metaTabSetting:
      objectToReturn = TAB_SETTINGS_OBJECT
      break
    case ALL_ENTITY_TYPES.tab:
      objectToReturn = TILES_OBJECT
      break
    case ALL_ENTITY_TYPES.displayMessageTemplates:
      objectToReturn = DISPLAY_MESSAGE_TEMPLATES_OBJECT
      break
    case ALL_ENTITY_TYPES.sentinelCard:
      objectToReturn = SENTINEL_CARD_OBJECT
      break
    case ALL_ENTITY_TYPES.planBundlePack:
      objectToReturn = PLAN_BUNDLE_PACKS_OBJECT
      break
    case ALL_ENTITY_TYPES.clinicBrand:
      objectToReturn = CLINIC_BRAND_OBJECT
      break
    case ALL_ENTITY_TYPES.prescriptionTemplate:
      objectToReturn = { ...PRESCRIPTION_TEMPLATES_OBJECT }
      break
    case ALL_ENTITY_TYPES.clinicAssistant:
      objectToReturn = { ...CLINIC_ASSISTANT_OBJECT }
      break
    case ALL_ENTITY_TYPES.paymentGateways:
      objectToReturn = { ...PAYMENT_GATEWAYS }
      break
    case ALL_ENTITY_TYPES.paymentGatewaySKU:
      objectToReturn = { ...PAYMENT_GATEWAY_SKU }
      break
    case ALL_ENTITY_TYPES.planGroups:
      objectToReturn = { ...META_PLAN_GROUPS }
      break
    case ALL_ENTITY_TYPES.subscriptionPlanType:
      objectToReturn = { ...META_SUBSCRIPTION_TYPES }
      break
    case ALL_ENTITY_TYPES.clinicChamber:
      objectToReturn = { ...CLINIC_CHAMBERS_OBJECT }
      break
    case ALL_ENTITY_TYPES.satelliteCall:
      objectToReturn = { ...SATELLITE_SCREEN_OBJECT }
      break
    case ALL_ENTITY_TYPES.courseCmsFieldType:
      objectToReturn = { ...COURSE_CMS_FIELD_TYPE_OBJECT }
      break
    case ALL_ENTITY_TYPES.onlineCourse:
    case ALL_ENTITY_TYPES.courseDraft:
      objectToReturn = { ...ONLINE_COURSE_OBJECT }
      break
    case ALL_ENTITY_TYPES.paywalls: {
      objectToReturn = { ...PAYWALL_OBJECT }
      break
    }
    case ALL_ENTITY_TYPES.guest: {
      objectToReturn = { ...GUEST_OBJECT }
      break
    }
    case ALL_ENTITY_TYPES.supportUser: {
      objectToReturn = { ...SUPPORT_USER_OBJECT }
      break
    }
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.sesTemplate:
      objectToReturn = { ...SES_TEMPLATE_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.investigation:
      objectToReturn = { ...INVESTIGATION_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.investigationSample:
      objectToReturn = { ...INVESTIGATION_SAMPLE_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceDuration:
      objectToReturn = { ...DRUG_ADVICE_DURATION_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceFrequency:
      objectToReturn = { ...DRUG_ADVICE_FREQUENCY_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceTiming:
      objectToReturn = { ...DRUG_ADVICE_TIMING_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.drugAdviceNote:
      objectToReturn = { ...DRUG_ADVICE_NOTE_OBJECT }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.instruction:
      objectToReturn = {
        ...INSTRUCTION_OBJECT,
        availableToAll: true,
        approvedForAllBy: appSettingsStore?.loggedInUserData?.userId,
        approvedForAllAt: moment().format(MOMENT_FORMATS.formatThatServerSends),
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.feeModel:
      objectToReturn = {
        ...FEE_MODEL_OBJECT,
      }
      break
    case ALL_ENTITY_TYPES_FROM_CONSTANTS.entityFeeModel:
      objectToReturn = {
        ...ENTITY_FEE_MODEL,
      }
      break
    default:
      toast.warning('Default Object(Meta Images Object) is being used.')
      objectToReturn = META_IMAGES_OBJECT
      break
  }
  return { ...objectToReturn, deleted: false, idByUser: false }
}

export function dontFetchUserNames(apiParam) {
  switch (apiParam) {
    default:
      return disableForContentWriter()
  }
}

export function urlPrmToApiParam(apiParam, location) {
  switch (apiParam) {
    case 'tags':
      return 'tag'
    case 'mentors':
      return 'mentor'
    case 'articles':
      return 'article'
    case 'agreements':
      return 'agreement'
    case 'user-roles':
      return 'role'
    case 'leaderboards':
      return 'badge'
    case 'mental-states':
      return 'mental-state'
    case 'mental-state-types':
      return 'mental-state-type'
    case 'companies':
      return 'company'
    case 'clinics':
      return 'clinic'
    case 'clinic-prospects':
      return 'clinic'
    case 'medicine-store':
      return 'drug-info'
    case 'journals':
      return 'journal'
    case 'quotes':
      return 'quote'
    case 'assessments':
      return 'assessment'
    case 'diagnosis':
      return 'diagnosis'
    case 'health-complaints':
      return 'health-complaint'
    case 'tab-settings':
      return ALL_ENTITY_TYPES.metaTabSetting
    case 'business-model-templates':
      return ALL_ENTITY_TYPES.feeModel
    case 'business-models-with-clinics-and-others':
    case 'business-models-with-clinics':
    case 'business-models-with-therapists':
      return ALL_ENTITY_TYPES.entityFeeModel
    default:
      return apiParam
  }
}

////////////////////EXTRA DATA////////////////////

export const StatsTabsToApiParamsMapper = {
  0: 'badge',
  1: 'user-stats',
  badge: 0,
  'user-stats': 1,
}

export function disableForContentWriter() {
  return roleArray?.includes('ROLE_CONTENT_WRITER') || false
}

async function cloneEntity(entity, item, titleField = 'title', storeData, refreshFunction) {
  const tst = toast.loading('Creating Clone')
  const detailedEntity = await GetDetailedEntity(entity, item)
  if (!!detailedEntity) {
    const clonedEntity = {
      ...detailedEntity,
      id: null,
      [titleField]: detailedEntity?.[titleField] + ' (Clone)',
    }
    toast.update(tst, {
      render: 'Clone Created Successfully',
      isLoading: false,
      type: toast.TYPE.SUCCESS,
      ...TOAST_UTILS,
    })
    const resp = await SaveEntity(entity, clonedEntity, refreshFunction, undefined, storeData)
  } else {
    toast.update(tst, {
      render: 'Something went wrong',
      isLoading: false,
      type: toast.TYPE.SUCCESS,
      ...TOAST_UTILS,
    })
  }
}

async function cloneActivityPlan(entity, item, titleField = 'title', storeData, refreshFunction) {
  const tst = toast.loading('Creating Clone')
  const detailedEntity = await GetDetailedEntity(entity, item)
  if (!!detailedEntity) {
    const clonedEntity = {
      ...detailedEntity,
      id: null,
      [titleField]: detailedEntity?.[titleField] + ' (Clone)',
    }

    toast.update(tst, {
      render: 'Clone Created Successfully',
      isLoading: false,
      type: toast.TYPE.SUCCESS,
      ...TOAST_UTILS,
    })
    const resp = await SaveEntity(entity, clonedEntity, refreshFunction, undefined, storeData)
  } else {
    toast.update(tst, {
      render: 'Something went wrong',
      isLoading: false,
      type: toast.TYPE.SUCCESS,
      ...TOAST_UTILS,
    })
  }
}

const DRAFT_PUBLISH_OPTIONS = [
  { id: 'draft', title: 'Draft' },
  { id: 'published', title: 'Published' },
]

const customFilterOptions = {
  kycStatus: [
    { id: 'approved', label: 'Approved' },
    { id: 'pending', label: 'Pending' },
    { id: null, label: 'Show All' },
  ],
  appListable: [
    { id: true, label: 'Listable Only' },
    { id: false, label: 'Not Listable Only' },
    { id: null, label: 'Show All' },
  ],
  servicesOptions: [
    { id: true, label: 'Show such mentors Only' },
    { id: false, label: "Don't such mentors" },
    { id: null, label: 'Show All' },
  ],
}

export const threeWayButtonStyles = {
  maxWidth: '500px',
  display: 'flex',
  alignItems: 'flex-end',
  padding: '2px 0px',
}

//////OLD ENTITY COMPONENTS

// export const entityComponents = {
//   images: {
//     switches: [
//       { id: 'hasAlpha', label: 'Is Transparent' },
//       { id: 'hasText', label: 'Image Has Text' },
//       { id: 'quoteEnabled', label: 'Enabled For Quotes' },
//       { id: 'hasBorder', label: 'Image has Border' },
//     ],
//     inputs: [
//       { id: 'altText', label: 'Alternative Text', placeholder: 'Enter Alternative Text...' },
//       { id: 'collection', label: 'Collection', placeholder: 'Enter Collection name...' },
//     ],
//     reactSelects: [
//       {
//         id: 'tags',
//         label: 'Tags',
//         placeholder: 'Enter Tags...',
//         noOptionsMessage: () => 'Enter Tags',
//         components: { DropdownIndicator: null },
//         dropDownOptionId: 'tags',
//       },
//     ],
//     fileInput: {
//       accept: 'image/*',
//       label: 'Upload Image',
//     },
//     ViewEditComponent: { component: ImageFormImagePreview, props: {} },
//     GalleryComponent: { component: GalleryComponent.imageGallery },
//   },
//   avatar: {
//     switches: [
//       { id: 'hasAlpha', label: 'Is Transparent' },
//       { id: 'hasText', label: 'Image Has Text' },
//       { id: 'quoteEnabled', label: 'Enabled For Quotes' },
//       { id: 'hasBorder', label: 'Image has Border' },
//     ],
//     htmlSelect: [
//       { id: 'gender', label: 'Gender', placeholder: 'Select Gender', dropDownOptionId: 'gender' },
//     ],
//     inputs: [
//       { id: 'altText', label: 'Alternative Text', placeholder: 'Enter Alternative Text...' },
//       { id: 'collection', label: 'Collection', placeholder: 'Enter Collection name...' },
//     ],
//     reactSelects: [
//       {
//         id: 'tags',
//         label: 'Tags',
//         placeholder: 'Enter Tags...',
//         noOptionsMessage: () => 'Enter Tags',
//         components: { DropdownIndicator: null },
//         dropDownOptionId: 'tags',
//       },
//     ],
//     fileInput: {
//       accept: 'image/*',
//       label: 'Upload Avatar',
//     },
//     ViewEditComponent: { component: ImageFormImagePreview, props: {} },
//   },
//   subtitle: {
//     switches: [],
//     inputs: [
//       { id: 'title', label: 'Title', placeholder: 'Enter Title...', invalid: validateField },
//     ],
//     reactSelects: [
//       {
//         id: 'languageCode',
//         label: 'Subtitle Language',
//         placeholder: 'Select Language...',
//         isMulti: false,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.languageName,
//         dropDownOptionId: 'languages',
//       },
//     ],
//     fileInput: {
//       accept: '.vtt,.srt,.txt',
//       label: 'Upload Subtitle',
//     },
//     htmlSelect: [
//       {
//         id: 'type',
//         label: 'File Type',
//         placeholder: 'Enter Subtitle Type...',
//         col: 2,
//         dropDownOptionId: 'subTitleType',
//       },
//     ],
//     ViewEditComponent: { component: ViewComponents.basicSubtitle, props: { bgColor: false } },
//     GalleryComponent: { component: GalleryComponent.subtitleGallery },
//   },
//   icon: {
//     inputs: [
//       {
//         id: 'id',
//         label: 'ID',
//         placeholder: 'Enter the id of the icon...',
//         disabledFunction: disableIdFieldIfMade,
//       },
//       { id: 'name', label: 'Name', placeholder: 'Enter Name...' },
//       { id: 'key', label: 'Key', placeholder: 'Enter Key...' },
//     ],
//     fileInput: {
//       accept: 'image/*',
//       label: 'Upload Icon',
//     },
//     ViewEditComponent: { component: ImageFormImagePreview, props: {} },
//   },
//   track: {
//     inputs: [
//       { id: 'title', label: 'Title', placeholder: 'Enter Title...' },
//       { id: 'duration', label: 'Duration', placeholder: 'Enter Duration...' },
//     ],
//     htmlSelect: [
//       {
//         id: 'type',
//         label: 'Type',
//         placeholder: 'Select Type...',
//         dropDownOptionId: 'trackType',
//       },
//     ],
//   },
//   badge: {
//     switches: [
//       { id: 'enabled', label: 'Enabled' },
//       { id: 'earned', label: 'Earned' },
//     ],
//     textArea: [
//       { id: 'description', label: 'Description', placeholder: 'Enter Description...', row: 3 },
//     ],
//     inputs: [
//       { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 4 },
//       {
//         id: 'maxCount',
//         label: 'Max Count',
//         placeholder: 'Enter a number...',
//         type: 'number',
//         required: true,
//         col: 3,
//       },
//     ],
//   },
//   'user-stats': {
//     switches: [{ id: 'enabled', label: 'Enabled' }],
//     htmlSelect: [
//       {
//         id: 'valueType',
//         label: 'Value Type',
//         placeholder: 'Select Value Type...',
//         dropDownOptionId: 'statValueType',
//       },
//     ],
//     inputs: [
//       { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 3 },
//       { id: 'sectionTitle', label: 'Section Title', placeholder: 'Enter Section Title...', col: 4 },
//     ],
//   },
//   'mental-state': {
//     switches: [
//       {
//         id: 'enabled',
//         label: 'Enabled',
//         disabledFunction: () => {
//           return disableForContentWriter()
//         },
//       },
//     ],
//     inputs: [{ id: 'name', label: 'Name', placeholder: 'Enter Name...', required: true, col: 4 }],
//     textArea: [
//       {
//         id: 'description',
//         label: 'Description',
//         placeholder: 'Enter Description...',
//         row: 3,
//       },
//     ],
//     reactSelects: [
//       {
//         id: 'type',
//         label: 'Mental State Type',
//         placeholder: 'Select a type...',
//         isMulti: false,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'mentalStateTypes',
//         col: 3,
//         changeType: changeType.string,
//       },
//     ],
//     imageUpload: true,
//   },
//   'mental-state-type': {
//     switches: [
//       {
//         id: 'enabled',
//         label: 'Enabled',
//         disabledFunction: () => {
//           return disableForContentWriter()
//         },
//       },
//     ],
//     inputs: [
//       {
//         id: 'id',
//         label: 'ID',
//         placeholder: 'Enter ID...',
//         required: true,
//         col: 4,
//         invalid: validateID,
//         feedbackInvalid: 'This id already exists or is empty.',
//         disabledFunction: disableIdFieldIfMade,
//       },
//       { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 4 },
//       { id: 'question', label: 'Question', placeholder: 'Enter Question...', col: 6 },
//       { id: 'answer', label: 'Answer', placeholder: 'Enter Answer...', col: 6 },
//     ],
//     nestedNestedInputs: [
//       {
//         id: 'dailyPlayAltText',
//         title: 'Daily Play Alternative Text',
//         fields: {
//           ...store.getState()?.timeSlots?.reduce((nestedArray, timeSlot) => {
//             const outerLabel = timeSlot?.title || timeSlot?.name
//             const questionProps = {
//               outerId: timeSlot.id,
//               id: 'question',
//               label: `${outerLabel}(${timeSlot.id}) Question`,
//               placeholder: 'Enter Question...',
//               col: 6,
//             }
//             const answerProps = {
//               outerId: timeSlot.id,
//               id: 'answer',
//               label: `${outerLabel}(${timeSlot.id}) Answer`,
//               placeholder: 'Enter Answer...',
//               col: 6,
//             }
//             nestedArray = {
//               ...nestedArray,
//               [outerLabel]: { label: null, value: [questionProps, answerProps] },
//             }
//             return nestedArray
//           }, {}),
//         },
//       },
//     ],
//   },
//   article: {
//     // switches: [
//     //   {
//     //     id: 'enabled',
//     //     label: 'Enabled',
//     //     disabledFunction: () => {
//     //       return disableForContentWriter()
//     //     },
//     //   },
//     // ],
//     // inputs: [
//     //   {
//     //     id: 'title',
//     //     label: 'Title',
//     //     placeholder: 'Enter title...',
//     //   },
//     // ],

//     // textArea: [
//     //   {
//     //     id: 'shortDesc',
//     //     label: 'Short Description',
//     //     placeholder: 'Enter Short Description...',
//     //     row: 3,
//     //   },
//     // ],
//     // reactSelects: [
//     //   {
//     //     id: 'primaryTags',
//     //     label: 'Primary Tags',
//     //     placeholder: 'Select Tag...',
//     //     isMulti: true,
//     //     getOptionValue: (option) => option.id,
//     //     getOptionLabel: (option) => option.title || option.name,
//     //     dropDownOptionId: 'tags',
//     //     col: 6,
//     //     changeType: changeType.string,
//     //     disabledFunction: disableForContentWriter,
//     //   },
//     //   {
//     //     id: 'secondaryTags',
//     //     label: 'Secondary Tags',
//     //     placeholder: 'Select Tag...',
//     //     isMulti: true,
//     //     getOptionValue: (option) => option.id,
//     //     getOptionLabel: (option) => option.title || option.name,
//     //     dropDownOptionId: 'tags',
//     //     col: 6,
//     //     changeType: changeType.string,
//     //     disabledFunction: disableForContentWriter,
//     //   },
//     // ],
//     // richTextInput: [
//     //   { id: 'content', rawId: 'rawContent', label: 'Content', placeholder: 'Enter content...' },
//     // ],
//   },
//   'meta-category-type': {
//     switches: [
//       { id: 'enabled', label: 'Enabled' },
//       { id: 'player', label: 'Player' },
//       { id: 'enabledForDailyPlay', label: 'Enabled for Daily Play' },
//     ],
//     inputs: [
//       {
//         id: 'id',
//         label: 'ID',
//         placeholder: 'Enter ID...',
//         required: true,
//         col: 4,
//         invalid: validateID,
//         feedbackInvalid: 'This id already exists or is empty.',
//       },
//       { id: 'title', label: 'Title', placeholder: 'Enter Title...', required: true, col: 4 },
//     ],
//     reactSelects: [
//       {
//         id: 'scope',
//         label: 'Scope',
//         placeholder: 'Select Scope...',
//         isMulti: false,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.label,
//         dropDownOptionId: 'categoryScope',
//         col: 6,
//         changeType: changeType.string,
//         required: true,
//       },
//       {
//         id: 'itemType',
//         label: 'Item Type',
//         placeholder: 'Select Item Type...',
//         isMulti: true,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name || option.id,
//         dropDownOptionId: 'itemTypes',
//         col: 6,
//         changeType: changeType.string,
//       },
//     ],
//   },
//   tag: {
//     switches: [{ id: 'enabled', label: 'Enabled' }],
//     inputs: [
//       {
//         id: 'id',
//         label: 'ID',
//         placeholder: 'Enter ID...',
//         required: true,
//         col: 4,
//         invalid: validateID,
//         feedbackInvalid: 'This id already exists or is empty.',
//       },
//       { id: 'tag', label: 'Name', placeholder: 'Enter Tag Name...', required: true, col: 4 },
//       {
//         id: 'customShortLink',
//         label: 'Custom Short Link',
//         placeholder: 'Enter Short Link...',
//         col: 4,
//       },
//     ],
//     textArea: [
//       {
//         id: 'shortDesc',
//         label: 'Short Description',
//         placeholder: 'Enter Short Description...',
//         row: 3,
//         col: 6,
//       },
//       {
//         id: 'welcomeMsg',
//         label: 'Welcome Message',
//         placeholder: 'Enter Welcome Message...',
//         row: 3,
//         col: 6,
//       },
//     ],
//     reactSelects: [
//       {
//         id: 'categories',
//         label: 'Categories',
//         placeholder: 'Select Categories...',
//         isMulti: true,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'categories',
//         col: 6,
//         changeType: changeType.string,
//       },
//       {
//         id: 'listabilityPolicy',
//         label: 'Listability Policy',
//         placeholder: 'Select Policy...',
//         isMulti: false,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'listabliltyPolicies',
//         col: 6,
//         changeType: changeType.string,
//       },
//       {
//         id: 'type',
//         label: 'Tag Type',
//         placeholder: 'Select Type...',
//         isMulti: false,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'tagType',
//         col: 6,
//         changeType: changeType.string,
//         required: true,
//       },
//     ],
//   },
//   agreement: {
//     switches: [{ id: 'enabled', label: 'Enabled' }],
//     inputs: [
//       {
//         id: 'title',
//         label: 'Title',
//         placeholder: 'Enter title...',
//         required: true,
//         col: 4,
//       },
//       {
//         id: 'type',
//         label: 'Agreement Type',
//         placeholder: 'Enter agreement type...',
//         required: true,
//         col: 4,
//       },
//     ],
//     textArea: [
//       {
//         id: 'shortDesc',
//         label: 'Short Description',
//         placeholder: 'Enter Short Description...',
//         row: 3,
//         col: 12,
//       },
//     ],
//     reactSelects: [
//       {
//         id: 'includedCountries',
//         label: 'Included Countries',
//         placeholder: 'Select Included Countries...',
//         isMulti: true,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'countries',
//         col: 6,
//         changeType: changeType.string,
//         required: false,
//       },
//       {
//         id: 'excludedCountries',
//         label: 'Excluded Countries',
//         placeholder: 'Select Excluded Countries...',
//         isMulti: true,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'countries',
//         col: 6,
//         changeType: changeType.string,
//         required: false,
//       },
//       {
//         id: 'roles',
//         label: 'Roles',
//         placeholder: 'Select Agreement Roles...',
//         isMulti: true,
//         getOptionValue: (option) => option.name,
//         getOptionLabel: (option) => option.title,
//         dropDownOptionId: 'roles',
//         col: 6,
//         changeType: changeType.string,
//         required: false,
//       },
//       {
//         id: 'metaCategories',
//         label: 'Categories',
//         placeholder: 'Select Categories...',
//         isMulti: true,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title || option.name,
//         dropDownOptionId: 'metaCategories',
//         col: 6,
//         changeType: changeType.string,
//         required: false,
//         filterOptionBasedOn: filterAgreementCategories,
//         optionsBasedOn: 'roles',
//         viewBasedOn: 'roles',
//         viewBasedOnCalculator: (roles, dropDownData) => {
//           const allRoles = dropDownData?.roles
//           const selectedRoles = Array.isArray(roles)
//             ? roles?.map(
//                 (role) =>
//                   allRoles?.find((rol) => rol?.name === role) || { name: role, title: role },
//               ) || []
//             : []
//           return selectedRoles?.some((role) => role?.agreementRequired)
//         },
//       },
//     ],
//     dateTimeInput: [
//       {
//         id: 'applicableSince',
//         label: 'Applicable Since',
//         placeholder: 'Enter title...',
//         required: true,
//         col: 4,
//       },
//     ],
//     richTextInput: [{ id: 'content', label: 'Content', placeholder: 'Enter content...' }],
//   },
//   role: {
//     switches: [
//       { id: 'enabled', label: 'Enabled' },
//       { id: 'agreementRequired', label: 'Agreement Required' },
//     ],
//     inputs: [
//       {
//         id: 'name',
//         label: 'Name',
//         placeholder: 'Enter Role Name...',
//         required: true,
//         col: 4,
//       },
//       {
//         id: 'title',
//         label: 'Title',
//         placeholder: 'Enter role title...',
//         required: true,
//         col: 4,
//       },
//       {
//         id: 'portalUrl',
//         label: 'Portal URL',
//         placeholder: 'Enter the URL...',
//         required: false,
//         col: 4,
//       },
//     ],
//     textArea: [
//       {
//         id: 'description',
//         label: 'Description',
//         placeholder: 'Enter Description...',
//         row: 3,
//       },
//     ],
//     reactSelects: [
//       {
//         id: 'metaCategoryTypes',
//         label: 'Meta Category Types',
//         placeholder: 'Select types...',
//         isMulti: true,
//         getOptionValue: (option) => option.id,
//         getOptionLabel: (option) => option.title,
//         dropDownOptionId: 'metaCategoryTypes',
//         col: 6,
//         changeType: changeType.string,
//         required: false,
//       },
//     ],
//   },
//   'support-staff': {
//     customComponent: ViewComponents.supportStaffForm,
//   },
//   hints: {
//     inputs: [
//       {
//         id: 'hint',
//         label: 'Hint',
//         placeholder: 'Enter the hint name...',
//         required: true,
//         col: 12,
//       },
//     ],
//   },
//   'drug-info': {
//     customComponent: ViewComponents.drugInfoForm,
//   },
//   rest: {
//     customComponent: ViewComponents.restComponentForm,
//   },
//   'plan-phase': {
//     customComponent: ViewComponents.planPhaseComponent,
//   },
//   activity: {
//     customComponent: ViewComponents.activityComponent,
//   },
//   diagnosis: {
//     inputs: [
//       {
//         id: 'title',
//         label: 'Title',
//         placeholder: 'Enter the title...',
//         required: true,
//         col: 12,
//       },
//     ],
//   },
//   'health-complaint': {
//     inputs: [
//       {
//         id: 'title',
//         label: 'Title',
//         placeholder: 'Enter the title...',
//         required: true,
//         col: 12,
//       },
//     ],
//   },
// }

///OLD DRAFT.JS TOOLBAR OPTIONS
// inline: {
//   inDropdown: false,
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
//   options: [
//     'bold',
//     'italic',
//     'underline',
//     'strikethrough',
//     'monospace',
//     'superscript',
//     'subscript',
//   ],
//   bold: { className: undefined },
//   italic: { className: undefined },
//   underline: { className: undefined },
//   strikethrough: { className: undefined },
//   monospace: { className: undefined },
//   superscript: { className: undefined },
//   subscript: { className: undefined },
// },
// blockType: {
//   inDropdown: true,
//   options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
// },
// fontSize: {
//   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
// },
// fontFamily: {
//   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
// },
// list: {
//   inDropdown: false,
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
//   options: ['unordered', 'ordered', 'indent', 'outdent'],
//   unordered: { className: undefined },
//   ordered: { className: undefined },
//   indent: { className: undefined },
//   outdent: { className: undefined },
// },
// textAlign: {
//   inDropdown: false,
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
//   options: ['center', 'right', 'justify'],
//   left: { className: undefined },
//   center: { className: undefined },
//   right: { className: undefined },
//   justify: { className: undefined },
// },
// colorPicker: {
//   className: undefined,
//   component: undefined,
//   popupClassName: undefined,
//   colors: [
//     'rgb(97,189,109)',
//     'rgb(26,188,156)',
//     'rgb(84,172,210)',
//     'rgb(44,130,201)',
//     'rgb(147,101,184)',
//     'rgb(71,85,119)',
//     'rgb(204,204,204)',
//     'rgb(65,168,95)',
//     'rgb(0,168,133)',
//     'rgb(61,142,185)',
//     'rgb(41,105,176)',
//     'rgb(85,57,130)',
//     'rgb(40,50,78)',
//     'rgb(0,0,0)',
//     'rgb(247,218,100)',
//     'rgb(251,160,38)',
//     'rgb(235,107,86)',
//     'rgb(226,80,65)',
//     'rgb(163,143,132)',
//     'rgb(239,239,239)',
//     'rgb(255,255,255)',
//     'rgb(250,197,28)',
//     'rgb(243,121,52)',
//     'rgb(209,72,65)',
//     'rgb(184,49,47)',
//     'rgb(124,112,107)',
//     'rgb(209,213,216)',
//   ],
// },
// link: {
//   inDropdown: false,
//   className: undefined,
//   component: undefined,
//   popupClassName: undefined,
//   dropdownClassName: undefined,
//   showOpenOptionOnHover: true,
//   defaultTargetOption: '_self',
//   options: ['link', 'unlink'],
//   link: { className: undefined },
//   unlink: { className: undefined },
//   linkCallback: undefined,
// },
// emoji: {
//   className: undefined,
//   component: undefined,
//   popupClassName: undefined,
//   emojis: [
//     '😀',
//     '😁',
//     '😂',
//     '😃',
//     '😉',
//     '😋',
//     '😎',
//     '😍',
//     '😗',
//     '🤗',
//     '🤔',
//     '😣',
//     '😫',
//     '😴',
//     '😌',
//     '🤓',
//     '😛',
//     '😜',
//     '😠',
//     '😇',
//     '😷',
//     '😈',
//     '👻',
//     '😺',
//     '😸',
//     '😹',
//     '😻',
//     '😼',
//     '😽',
//     '🙀',
//     '🙈',
//     '🙉',
//     '🙊',
//     '👼',
//     '👮',
//     '🕵',
//     '💂',
//     '👳',
//     '🎅',
//     '👸',
//     '👰',
//     '👲',
//     '🙍',
//     '🙇',
//     '🚶',
//     '🏃',
//     '💃',
//     '⛷',
//     '🏂',
//     '🏌',
//     '🏄',
//     '🚣',
//     '🏊',
//     '⛹',
//     '🏋',
//     '🚴',
//     '👫',
//     '💪',
//     '👈',
//     '👉',
//     '👉',
//     '👆',
//     '🖕',
//     '👇',
//     '🖖',
//     '🤘',
//     '🖐',
//     '👌',
//     '👍',
//     '👎',
//     '✊',
//     '👊',
//     '👏',
//     '🙌',
//     '🙏',
//     '🐵',
//     '🐶',
//     '🐇',
//     '🐥',
//     '🐸',
//     '🐌',
//     '🐛',
//     '🐜',
//     '🐝',
//     '🍉',
//     '🍄',
//     '🍔',
//     '🍤',
//     '🍨',
//     '🍪',
//     '🎂',
//     '🍰',
//     '🍾',
//     '🍷',
//     '🍸',
//     '🍺',
//     '🌍',
//     '🚑',
//     '⏰',
//     '🌙',
//     '🌝',
//     '🌞',
//     '⭐',
//     '🌟',
//     '🌠',
//     '🌨',
//     '🌩',
//     '⛄',
//     '🔥',
//     '🎄',
//     '🎈',
//     '🎉',
//     '🎊',
//     '🎁',
//     '🎗',
//     '🏀',
//     '🏈',
//     '🎲',
//     '🔇',
//     '🔈',
//     '📣',
//     '🔔',
//     '🎵',
//     '🎷',
//     '💰',
//     '🖊',
//     '📅',
//     '✅',
//     '❎',
//     '💯',
//   ],
// },
// embedded: {
//   className: undefined,
//   component: undefined,
//   popupClassName: undefined,
//   embedCallback: undefined,
//   defaultSize: {
//     height: 'auto',
//     width: 'auto',
//   },
// },
// image: {
//   className: undefined,
//   component: undefined,
//   popupClassName: undefined,
//   urlEnabled: true,
//   uploadEnabled: true,
//   alignmentEnabled: true,
//   uploadCallback: undefined,
//   previewImage: false,
//   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//   alt: { present: false, mandatory: false },
//   defaultSize: {
//     height: 'auto',
//     width: 'auto',
//   },
// },
// remove: { className: undefined, component: undefined },
// history: {
//   inDropdown: false,
//   className: undefined,
//   component: undefined,
//   dropdownClassName: undefined,
//   options: ['undo', 'redo'],
//   undo: { className: undefined },
//   redo: { className: undefined },
// },
