export function mapDropdown(options) {
  return options
}

export function array_move(array, old_index, new_index) {
  const arr = [...(array || [])]

  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)?.[0])
  return arr
}

export function dragCssChange(event, css) {
  event.target.className += css
}
//TODO: THIS NEEDS TO CHANGE
