import { CFormLabel } from '@coreui/react-pro'
import { South } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
} from '@mui/material'
import { toast } from 'react-toastify'
import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ConfirmationModal from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import { transformFetchedEntity } from 'src/config/genericForm/fetch/fetchEntityTransformer'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity, SaveEntity } from 'src/utilities/apiGetters'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS as ALL_ENTITY_TYPES } from 'src/utilities/constants'
import { getRoleTitleForMentor } from 'src/utilities/generalUtils'
import { array_move } from 'src/views/curatedLists/utils/curatedListData-utils'
import OohReorderListItem from './OohReorderListItem'
import ShowEnlargedOOH from './ShowEnlargedOOH'
import { StyledOOHListReorderItem } from './styledComponents'
import { getChangedOohMsgs } from '../ooh-message-utils'
import { TOAST_UTILS } from 'src/components/controlCenter/toast-utils'

export default function ReorderOohMsgs({
  listingData: states,
  handleClose,
  handleRefresh,
  customOptions,
}) {
  const [reOrderedOoh, setReordedOoh] = useState([])
  const [initialOoh, setInitialOoh] = useState([])
  const changedArr = getChangedOohMsgs(
    initialOoh,
    reOrderedOoh.map((oohMsg, idx) => {
      return { ...oohMsg, orderId: idx }
    }),
  )

  const changes = changedArr?.length > 0 ? changedArr : null
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState()
  const [allOohMsgs, setAllOohMsgs] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState({
    mentor: false,
    clinic: false,
  })
  const [selectedMentor, setSelectedMentor] = useState()
  const [selectedOOH, setSelectedOOH] = useState()
  const [showEnlargedMedia, setShowEnlargedMedia] = useState(false)

  const currentMentors = useMemo(() => {
    const mentrContacts =
      [...(selectedClinic?.doctors || []), ...(selectedClinic?.therapists || [])] || []

    const teacherIdsToConsider = mentrContacts?.map((cont) => cont?.uid)

    const mentorObjects = customOptions?.mentors?.filter((teach) => {
      return teacherIdsToConsider?.includes(teach?.id)
    })
    return mentorObjects
  }, [customOptions, selectedClinic])

  async function saveOohMsg(oohMsg, isDuplicating = false, isRefreshingListing = false) {
    const entityToSave = await GetDetailedEntity(ALL_ENTITY_TYPES.oohMsg, oohMsg)
    const resp = await SaveEntity(
      ALL_ENTITY_TYPES.oohMsg,
      {
        ...entityToSave,
        id: isDuplicating ? null : oohMsg?.id,
        title: oohMsg?.title,
        youtubeUrl: oohMsg?.youtubeUrl,
        durationInSec: oohMsg?.durationInSec,
        orderId: oohMsg?.orderId,
        createdTime: oohMsg?.createdTime,
        createdBy: oohMsg?.createdBy,
        modifiedTime: oohMsg?.modifiedTime,
        modifiedBy: oohMsg?.modifiedBy,
      },
      () => {},
      () => {},
      {},
      false,
    )
    if (isRefreshingListing) {
      handleRefresh()
    }
    return resp
  }

  async function saveOrder() {
    const tst = toast.loading('Saving Order...')
    const oohMsgsToSave = getChangedOohMsgs(
      initialOoh,
      reOrderedOoh.map((oohMsg, idx) => {
        return { ...oohMsg, orderId: idx }
      }),
    )

    console.log({
      oohMsgsToSave,
    })

    const responses = await Promise.all(
      oohMsgsToSave.map(async (oohMsg, idx) => {
        return saveOohMsg(oohMsg)
      }),
    )
    if (responses?.every((resp) => resp.status === 200)) {
      getOohMsg()
      toast.update(tst, {
        render: 'Saved Successfully',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        ...TOAST_UTILS,
      })
    } else {
      toast.update(tst, {
        render: 'One or more msgs could not be saved',
        type: toast.TYPE.ERROR,
        isLoading: false,
        ...TOAST_UTILS,
      })
    }
    // handleClose()
  }

  async function duplicateOoh(msgToSave) {
    const tst = toast.loading('Duplicating OOH Message')

    let oohMsgToSave = {
      ...msgToSave,
      orderId: 0,
      modifiedTime: null,
      modifiedBy: null,
      createdBy: null,
      createdTime: null,
    }
    const resp = await saveOohMsg(oohMsgToSave, true, true)
    if (!!resp) {
      const savedOoh = resp?.data
      setReordedOoh((prev) => [savedOoh, ...(prev || [])])
      setInitialOoh((prev) => [savedOoh, ...(prev || [])])
    }
    toast.dismiss(tst)
  }

  async function getOohMsg() {
    const oohResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.oohMsg,
      page: 0,
      pageSize: 999,
      // enabled: true,
      clinicId: selectedClinic?.id,
      mentorId: selectedMentor?.id,
    })
    if (oohResponse.status === 200) {
      const oohResponseData = oohResponse.data.content
      setAllOohMsgs(oohResponseData)
      const sortedOoh = oohResponseData
        .filter((oohMsg) => oohMsg.enabled)
        .sort((o1, o2) => sortFn(o1.orderId, o2.orderId))
        .map((oohMsg, idx) => ({ ...oohMsg, orderId: idx }))

      setInitialOoh([...sortedOoh])
      setReordedOoh([...sortedOoh])
    }
  }

  function handleDragEnd(results) {
    const { source, destination } = results
    if (source && destination && source?.index !== destination?.index) {
      console.log('drag end')
      setReordedOoh((prev) => {
        const newArray = array_move(prev, source.index, destination.index)

        return newArray
      })
    }
  }

  function openEnlargedMedia(oohMsg) {
    setSelectedOOH(oohMsg)
    setShowEnlargedMedia(true)
  }

  function closeEnlargedMedia() {
    setShowEnlargedMedia(false)
  }

  async function changeClinic(value) {
    setLoadingDetails((prev) => ({ ...prev, clinic: true }))

    console.log({ value })

    const clinicDetails = await GetDetailedEntity(ALL_ENTITY_TYPES?.clinic, { id: value?.id })
    const clinicDataToSet = await transformFetchedEntity(ALL_ENTITY_TYPES.clinic, clinicDetails)
    if (!!clinicDetails) {
      setSelectedClinic(clinicDataToSet)
    }
    setLoadingDetails((prev) => ({ ...prev, clinic: false }))
  }

  function setOOHBasedOnIdx(prevObj, idx) {
    setReordedOoh((prev) => {
      const toReturn = (prev || []).map((ooh, oohIdx) => {
        if (idx !== oohIdx) {
          return ooh
        }
        if (typeof prevObj === 'function') {
          return prevObj(ooh)
        }
        return prevObj
      })

      // setChanges(toReturn)
      return toReturn
    })
  }

  async function Setup() {
    setLoading(true)
    const functions = [getOohMsg()]
    await Promise.all(functions)
    setLoading(false)
  }

  useEffect(() => {
    console.log({ selectedClinic, selectedMentor })
    if (!!selectedClinic && !!selectedMentor) {
      Setup()
    }
  }, [selectedClinic, selectedMentor])

  useEffect(() => {
    console.log({ reOrderedOoh })
  }, [reOrderedOoh])

  console.log({ changes, changedArr })
  return (
    <>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Re-Order OOH Messages</span>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="clinicId"
              value={selectedClinic}
              isLoading={loadingDetails?.clinic}
              options={customOptions?.clinics || []}
              getOptionValue={(object) => object?.id}
              getOptionLabel={(object) => object?.title || object?.id}
              label="Clinic"
              onChangeEvent={(e) => changeClinic(e.target.value)}
              selectType={selectTypes.object}
              placeholder="Select a clinic..."
              required
            />
          </Grid>
          {!!selectedClinic && (
            <Grid item xs={12} md={6}>
              <CFormLabel>Mentor</CFormLabel>
              <CustomReactSelect
                id="mentorId"
                value={selectedMentor}
                isLoading={loadingDetails?.clinic}
                options={currentMentors?.sort((a, b) => a.name.localeCompare(b.name))}
                getOptionValue={(object) => object?.id}
                getOptionLabel={(object) =>
                  `${object?.name || object?.title || object?.id} (${getRoleTitleForMentor(
                    object,
                  )})`
                }
                onChange={(value) => setSelectedMentor(value)}
                selectType={selectTypes.object}
                placeholder="Select a mentor..."
                required
              />
            </Grid>
          )}
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable id="dnd-faq-category" droppableId="Cats" type="group">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ bgcolor: 'background.paper' }}
                >
                  <StyledOOHListReorderItem className="fw-bold">
                    <div className="d-flex align-items-center justify-content-center">
                      <span>#</span>
                      <South fontSize="small" />
                    </div>
                    <div>
                      <span>OOH Msg</span>
                    </div>
                    <div>
                      <span>Title</span>
                    </div>
                    <div>
                      <span>Youtube URL</span>
                    </div>
                    <div>
                      <span>Slide Duration (In Sec)</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center"></div>
                  </StyledOOHListReorderItem>
                  {reOrderedOoh?.map((oohMsg, idx) => (
                    <Draggable key={oohMsg.id} draggableId={`${oohMsg.id}`} index={idx}>
                      {(provided) => (
                        <div
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <OohReorderListItem
                            oohMsg={oohMsg}
                            setOOHMsg={(prevObj) => {
                              setOOHBasedOnIdx(prevObj, idx)
                            }}
                            idx={idx + 1}
                            duplicateOoh={duplicateOoh}
                            openEnlargedMedia={openEnlargedMedia}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={!changes}
          onClick={() => setShowConfirmation(true)}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal
        action="submit"
        body="Do you want to save"
        visibility={showConfirmation}
        visibilitySetter={setShowConfirmation}
        onSubmitFunctions={[() => saveOrder()]}
      />
      <Dialog
        PaperProps={{ sx: { maxWidth: '650px', background: 'transparent', boxShadow: 'unset' } }}
        open={showEnlargedMedia}
        onClose={closeEnlargedMedia}
      >
        <DialogContent>
          <ShowEnlargedOOH oohMsg={selectedOOH} />
        </DialogContent>
      </Dialog>
    </>
  )
}

const listItemStyles = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: 2,
  px: 2,
}
