import { CFormInput } from '@coreui/react-pro'
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { fileUploadToApi } from 'src/components/controlCenter/imageUpload/ImageUpload'
import { getMediaImageUrl } from 'src/services'
import { HLS_TRACK_TYPES } from 'src/utilities/constants'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'

export default function TrackSubtitles({
  allSubtitles,
  selectedSubtitle,
  closeSubtitleDialog,
  changeSubtitles,
  trackObject,
}) {
  const subtitleInputRef = useRef()
  const [uploadingSubtitle, setUploadingSubtitle] = useState(false)
  const [subtitleFile, setSubtitleFile] = useState()
  const [subtitleUrl, setSubtitleUrl] = useState()
  const [subtitleLanguage, setSubtitleLanguage] = useState(selectedSubtitle?.subtitleLanguageCode)
  const [store] = useAppSettingsStore()
  const [playerKey, setPlayerKey] = useState(0)

  async function updateTrackObjectWithSubtitleDetails() {
    setUploadingSubtitle(true)

    const subtitleFileResponseData = await fileUploadToApi(subtitleFile, true)
    const subtitleFileId = subtitleFileResponseData?.fileId
    changeSubtitles(subtitleFileId, subtitleLanguage)
    subtitleInputRef.current.value = ''
    closeSubtitleDialog()
    setUploadingSubtitle(false)
  }

  function handleChangeSubtitleFileId(e) {
    if (!e.target.files[0]) return
    setSubtitleFile(e.target.files[0])
    const reader = new FileReader()
    reader.onload = () => {
      setSubtitleUrl(reader.result)
    }
    reader.readAsDataURL(e.target.files[0])
    setPlayerKey((prev) => prev + 1)
  }

  console.log({ selectedSubtitle })

  return (
    <>
      <DialogTitle>Upload Subtitle</DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column gap-2">
          <CustomReactSelect
            value={subtitleLanguage}
            options={[
              ...store?.languages?.filter(
                (lang) => !allSubtitles.find((sub) => sub.subtitleLanguageCode === lang.id),
              ),
              ...(selectedSubtitle?.subtitleLanguageCode
                ? [
                    store?.languages.find(
                      (lang) => lang.id === selectedSubtitle?.subtitleLanguageCode,
                    ),
                  ]
                : []),
            ]}
            onChangeEvent={(e) => {
              setSubtitleLanguage(e.target.value)
            }}
            selectType={selectTypes.string}
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.languageName}
            label="Subtitle Language"
            id="subtitle-language"
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
          <CFormInput
            ref={subtitleInputRef}
            type="file"
            accept=".vtt,.srt,.txt"
            onChange={handleChangeSubtitleFileId}
          />
          <ReactPlayer
            key={playerKey}
            url={`${trackObject?.url}`}
            style={{
              padding: '10px',
              maxWidth: 'unset',
            }}
            height={trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px'}
            width={'100%'}
            controls={true}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'true',
                },
                tracks: [
                  {
                    kind: 'subtitles',
                    src: subtitleUrl
                      ? subtitleUrl
                      : `${getMediaImageUrl()}${selectedSubtitle?.subtitleFileId}`,
                    srcLang: selectedSubtitle?.subtitleLanguageCode,
                    default: true,
                  },
                ],
              },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSubtitleDialog} color="secondary">
          Close
        </Button>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            onClick={updateTrackObjectWithSubtitleDetails}
            loading={uploadingSubtitle}
            disabled={!(subtitleLanguage && subtitleFile)}
            variant={'contained'}
          >
            Upload Subtitle
          </Button>
          {uploadingSubtitle && <CircularProgress />}
        </div>
      </DialogActions>
    </>
  )
}
