import { styled as muiStyled } from '@mui/material'

export const StyledOOHListReorderItem = muiStyled('div')(
  ({ theme, isEven }) => `
    background: ${isEven ? '#e5e5e5' : theme.palette.background.default};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 0.5fr 1fr;
    align-items: center;
    padding: 8px;
    gap: 8px;
`,
)
