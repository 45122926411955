import { ContentCopy, ControlPointDuplicate, DragHandle } from '@mui/icons-material'
import { Button, IconButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import ReactPlayer from 'react-player/lib'
import ImageRenderComponent from 'src/components/controlCenter/imageUpload/ImageRenderComponent'
import { OOH_MSG_TYPES } from 'src/utilities/constants'
import { StyledOOHListReorderItem } from './styledComponents'
import { CCFormInput } from 'src/components/controlCenter/FormComponents'
import { getChangedNumberValueFromInput, getNumberStringForInput } from 'src/utilities/generalUtils'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'

export default function OohReorderListItem({
  oohMsg,
  idx,
  openEnlargedMedia,
  setOOHMsg,
  duplicateOoh,
}) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )

  function dupOoh() {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'duplicate',
      body: 'Do you want to duplicate this ooh message?',
      buttonColor: 'success',
      onSubmitFunctions: [
        () => {
          duplicateOoh(oohMsg)
        },
      ],
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
    }))
  }

  return (
    <>
      <StyledOOHListReorderItem isEven={idx % 2 !== 0}>
        <div className="d-flex align-items-center justify-content-center">
          <span>{idx}</span>
        </div>
        <div
          onClick={(e) => {
            openEnlargedMedia(oohMsg)
          }}
          style={{
            width: '200px',
            height: '150px',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: '2',
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              zIndex: 0,
              height: '100%',
              width: '100%',
            }}
          >
            {oohMsg?.imageType === OOH_MSG_TYPES.image ? (
              <ImageRenderComponent src={oohMsg?.imageId} />
            ) : (
              <ReactPlayer
                url={oohMsg?.youtubeUrl}
                height="auto"
                width="auto"
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            )}
          </div>
        </div>
        <div>
          <CCFormInput
            value={oohMsg?.title || ''}
            onChange={(e) => {
              setOOHMsg((prev) => {
                return { ...prev, title: e?.target?.value || '' }
              })
            }}
          />
        </div>
        <div>
          {oohMsg?.imageType === 'youtube_video' ? (
            <CCFormInput
              value={oohMsg?.youtubeUrl || ''}
              onChange={(e) => {
                setOOHMsg((prev) => {
                  return { ...prev, youtubeUrl: e?.target?.value || '' }
                })
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          <CCFormInput
            value={getNumberStringForInput(oohMsg?.durationInSec)}
            type="number"
            onChange={(e) => {
              setOOHMsg((prev) => {
                return { ...prev, durationInSec: getChangedNumberValueFromInput(e?.target?.value) }
              })
            }}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <Button endIcon={<ContentCopy />} onClick={() => dupOoh()}>
            Duplicate
          </Button>
          <DragHandle />
        </div>
      </StyledOOHListReorderItem>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}
