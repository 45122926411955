import { getExtraDataForFormAndListing } from 'src/config/common/genericListingAndFormConfig'
import { getReduxStoreData } from 'src/getDataFromRedux'

export async function getCustomFunctionData(prev, entity, location) {
  const customFormOptions = {}
  const rStore = getReduxStoreData()
  const storeDropDownData = rStore?.storeDropDownData || {}

  const extraDataToFetch = await getExtraDataForFormAndListing({
    apiParam: entity,
    locationParams: location,
  })

  await Promise.all(
    Object.entries(extraDataToFetch || {})
      ?.filter(([k, v]) => !!v)
      ?.map(async ([key, functionData]) => {
        const storeItem = storeDropDownData?.[key]

        // if (!!storeItem) {
        const data = await functionData.function(
          functionData.url,
          functionData.params,
          functionData.arg3,
          functionData.arg4,
          functionData?.arg5,
          functionData?.arg6,
        )
        if (data) {
          customFormOptions[key] = data
          // store.dispatch(dropDownDataSetToStoreBuilder(data, key))
        }
        return 1
        // }
        // customFormOptions[key] = storeItem
        // functionData
        //   .function(
        //     functionData.url,
        //     functionData.params,
        //     functionData.arg3,
        //     functionData.arg4,
        //     functionData?.arg5,
        //   )
        //   .then((data) => {
        //     store.dispatch(setDropDownDataToStore(data, key))
        //     customFormOptions[key] = data
        //   })
        // return 1
      }),
  )

  return { ...prev, ...customFormOptions }
}
