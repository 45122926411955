import { ALL_ENTITY_TYPES_FROM_CONSTANTS } from 'src/utilities/constants'
import { getSummarizableObject } from '../abstractObjects/Summarizable'
import { FEE_RANGE } from './FeeRange'

export const ENTITY_FEE_MODEL = {
  ...getSummarizableObject(ALL_ENTITY_TYPES_FROM_CONSTANTS.entityFeeModel),
  entityId: '',
  entityType: '',
  applicableFrom: '',
  applicableTill: '',

  feeRange: FEE_RANGE,

  defaultModel: false,

  countryCode: 'in',
  currencyCode: 'inr',
}
