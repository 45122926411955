import React, { useEffect, useState } from 'react'
import CCPagination from 'src/components/controlCenter/CCPagination'
import { quoteOptionsList } from 'src/views/features/quotes/utils/quotes-utils'

export default function PaginatedListingTableHandlerWrapper({
  apiCallUrl,
  apiCallFunction,
  apiCallParams,
  data,
  setData,
  setLoading = () => {},

  setupDataDependencies = [],

  filterComponentFunction = (props) => <></>,
  isPaginated,
  children,
}) {
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: apiCallParams?.size || apiCallParams?.pageSize || 50,
  })
  const [totalPages, setTotalPages] = useState(0)

  async function getDataFunction({ currentPagination = pagination } = {}) {
    // setLoading((prev) => ({ ...prev, pageLoading: 'loading' }))
    setData((prev) => ({ ...prev, status: 'loading' }))

    const resp = await apiCallFunction(apiCallUrl, {
      ...apiCallParams,
      // ...(currentPagination || {}),
      page: currentPagination?.pageNumber || 0,
      size: currentPagination?.pageSize,
    })
    if (resp.status === 200) {
      setData((prev) => ({ ...prev, data: resp.data.content, status: 'sucess' }))
      setTotalPages(resp.data.totalPages)
    } else {
      setData((prev) => ({ ...prev, status: 'error' }))
    }
  }

  async function Setup() {
    if (data?.data?.length <= 0 || !data?.data) {
      if (data?.status !== 'success') {
        setData((prev) => ({ ...prev, status: 'loading' }))
      }
      // await getDataFunction()
      setPagination((prev) => ({ ...prev, pageNumber: 0 }))
    }
    setData((prev) => ({ ...prev, status: 'success' }))
  }

  function changePageNumber({ pageNumber, pageSize = pagination?.pageSize }) {
    const pg = pageNumber - 1
    // console.log({ pageNumber: pg, pageSize })
    setPagination((prev) => ({ ...prev, pageNumber: pg, pageSize }))
  }

  useEffect(() => {
    Setup()
  }, setupDataDependencies)

  useEffect(() => {
    if (isPaginated) {
      console.log({ pagination })
      getDataFunction({ currentPagination: pagination })
    }
  }, [pagination, isPaginated])

  return (
    <>
      {filterComponentFunction()}
      {children}
      {isPaginated && (
        <CCPagination
          paginationProps={{
            ...{
              activePage: (pagination?.pageNumber || 0) + 1,
              onActivePageChange: (activePage) => {
                changePageNumber({ pageNumber: activePage })
              },
              pages: totalPages,
            },
          }}
          pageSize={pagination?.pageSize}
          handlePageSizeChange={(itemsPerPage) => {
            changePageNumber({
              pageSize: itemsPerPage,
              pageNumber: 1,
            })
          }}
          itemsPerPageOptions={[...quoteOptionsList]}
        />
      )}
    </>
  )
}
