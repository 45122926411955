import { Grid } from '@mui/material'
import React from 'react'
import { CCFormInput } from 'src/components/controlCenter/FormComponents'

export default function ReferenceFeeRange({ feeRange, handleChangeFeeRange, disabled }) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <CCFormInput
            id="min"
            value={feeRange?.min}
            type="number"
            label="Fee Range Min"
            onChange={handleChangeFeeRange}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CCFormInput
            id="max"
            value={feeRange?.max}
            type="number"
            label="Fee Range Max"
            onChange={handleChangeFeeRange}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
