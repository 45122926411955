import { toast } from 'react-toastify'
import { useState } from 'react'
import { isCommonResponseSuccessful } from 'src/utilities/generalUtils'
import { PostSettingsData } from 'src/services/APIs/Settings/PostApis'
import moment from 'moment-timezone'
import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

export default function RescheudleComponent({ bookedEventInfo, afterActionFunction }) {
  const [sendCommunicationToClient, setSendCommunicationToClient] = useState(true)
  const [sendCommunicationToMentor, setSendCommunicationToMentor] = useState(true)
  const [rescheduling, setRescheduling] = useState(false)
  const [newStartTime, setNewStartTime] = useState(moment())

  async function rescheduleSession() {
    setRescheduling(true)
    const response = await PostSettingsData(
      'booked-event/reschedule',
      { newStartTime: moment.utc(newStartTime).format('YYYY-MM-DD HH:mm:ss') },
      {
        bookedEventId: bookedEventInfo?.id,
        sendCommunicationToClient: sendCommunicationToClient,
        sendCommunicationToMentor: sendCommunicationToMentor,
      },
    )
    setRescheduling(false)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      toast.success('Booking Rescheduled')
    } else {
      toast.error(`Failed: ${response?.data?.message}`)
    }
    afterActionFunction && afterActionFunction()
  }

  return (
    <div className="w-100 h-100 d-flex flex-column gap-2">
      <div className="mt-3">
        Scheduled for:{' '}
        <b>
          {moment.utc(bookedEventInfo?.startTime).local().format('DD MMM YYYY hh:mm a')} -{' '}
          {moment.utc(bookedEventInfo?.endTime).local().format('DD MMM YYYY hh:mm a')}
        </b>
      </div>
      <div>
        Mentor/Doctor/Therapist: <b>{bookedEventInfo?.mentorName}</b>
      </div>
      <div>
        Client/Patient: <b>{bookedEventInfo?.userName}</b>
      </div>
      <FormControlLabel
        sx={{ marginTop: '32px' }}
        labelPlacement="end"
        control={
          <Checkbox
            id="sendCommunicationToClient"
            checked={sendCommunicationToClient}
            onChange={(e) => {
              setSendCommunicationToClient(e.target.checked)
            }}
          />
        }
        label="Send Communication to Client"
      />
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            id="sendCommunicationToMentor"
            checked={sendCommunicationToMentor}
            onChange={(e) => {
              setSendCommunicationToMentor(e.target.checked)
            }}
          />
        }
        label="Send Communication to Mentor"
      />
      <div style={{ marginTop: '24px' }}>New Start Time</div>
      <DateTimePicker
        inputId={'newStartTime'}
        value={newStartTime}
        onChange={(date) => setNewStartTime(date)}
      />
      <Button
        sx={{ width: 'fit-content', marginTop: '32px' }}
        variant="contained"
        onClick={rescheduleSession}
      >
        {`Reschedule for - ${newStartTime.format('DD MMM YYYY hh:mm a')}`}
        {rescheduling && (
          <CircularProgress size={'16px'} sx={{ color: '#fff', marginLeft: '8px' }} />
        )}
      </Button>
    </div>
  )
}
