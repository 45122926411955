import React from 'react'
import { currency_list } from 'src/utilities/constants'
import { isValidNumber } from 'src/utilities/generalUtils'

export default function EntityFeeRangeView({ entityFeeRanges, allModels, currencyCode }) {
  const foundCurrency = currency_list?.find((curr) => curr?.code === currencyCode) || {
    code: currencyCode,
  }

  //   entityFeeRanges?.currencyCode

  function getCurrentModel(modelId) {
    return allModels?.find((mod) => mod?.id === modelId) || null
  }

  console.log({ entityFeeRanges })
  return (
    <td>
      <div className="d-flex flex-column gap-1">
        {entityFeeRanges?.map((range, idx) => {
          return (
            <div className="d-flex align-items-center gap-1" key={idx}>
              <span>
                {range?.min}
                {foundCurrency?.symbol || foundCurrency?.code || ''}
              </span>{' '}
              {isValidNumber(range?.max) ? (
                <>
                  -{' '}
                  <span>
                    {range?.max}
                    {foundCurrency?.symbol || foundCurrency?.code || ''}
                  </span>
                </>
              ) : (
                `- ∞${foundCurrency?.symbol || foundCurrency?.code || ''}`
              )}
              {getCurrentModel(range?.modelId) && (
                <span>: ({getCurrentModel(range?.modelId)?.title || 'No Title'})</span>
              )}
            </div>
          )
        })}
      </div>
      {/* {item?.eventType}<br/> */}
    </td>
  )
}
// (Fee Range: ${getCurrentModel(range?.modelId)?.refFeeRange?.min} - ${
//     getCurrentModel(range?.modelId)?.refFeeRange?.max
//                   })`)
