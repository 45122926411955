import { styled as muiStyled, Paper } from '@mui/material'

export const StyledPayoutListView = muiStyled('div')(
  ({ theme }) => `
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
`,
)

export const ModelViewDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
max-width: 576px !important;
// height: 100%;
width: 100%;
padding: 16px;
margin: 0 !important;
border-radius: 8px;
@media(min-width: 576px){
  margin: 32px;
  max-width: 90vw;
}
`,
)