import { CFormLabel } from '@coreui/react-pro'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useMemo, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import {
  CCFormInput,
  CustomReactSelect,
  selectTypes,
} from 'src/components/controlCenter/FormComponents'
import EntityBasedImageUploadComponent from 'src/components/controlCenter/backgroundSlides/ImageUploadComponents/EntityBasedImageUploadComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { transformFetchedEntity } from 'src/config/genericForm/fetch/fetchEntityTransformer'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity } from 'src/utilities/apiGetters'
import { OOH_MSG_TYPES, OOH_MSG_TYPES_ARRAY } from 'src/utilities/constants'
import {
  getRoleTitleForMentor,
  isValidUrl,
  isValidYouTubeUrl,
  loadOptionsForAsyncPaginate,
} from 'src/utilities/generalUtils'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'

const OohMessagesForm = ({
  initialObject = {},
  imageObject,
  handleChangeObject,
  handleNestedChangeObject,
  handleNestedNestedChangeObject,
  handleReactSelectChange,
  handleRichTextChange = () => {},
  handleImageChange = () => {},
  entity,
  allImages,
  customOptions = {},
  disabled = false,
}) => {
  // Your component logic here
  const [loadingDetails, setLoadingDetails] = useState({
    mentor: false,
    clinic: false,
  })

  const currentMentors = useMemo(() => {
    const mentrContacts =
      [...(imageObject?.clinicId?.doctors || []), ...(imageObject?.clinicId?.therapists || [])] ||
      []

    const teacherIdsToConsider = mentrContacts?.map((cont) => cont?.uid)

    const mentorObjects = customOptions?.mentors?.filter((teach) => {
      return teacherIdsToConsider?.includes(teach?.id)
    })
    return mentorObjects
  }, [customOptions, imageObject])

  // async function GetMentorSelectedMentor() {
  //   setLoadingDetails((prev) => ({ ...prev, mentor: true }))
  //   const mentorDetails = await GetDetailedEntity('mentor', { id: imageObject?.mentorId })
  //   if (!!mentorDetails) {
  //     handleChangeObject({ target: { id: 'mentorId', value: mentorDetails } })
  //   }
  //   setLoadingDetails((prev) => ({ ...prev, mentor: false }))
  // }

  async function GetSelectedClinic() {
    setLoadingDetails((prev) => ({ ...prev, clinic: true }))
    const clinicDetails = await GetDetailedEntity(ALL_ENTITY_TYPES?.clinic, {
      id: imageObject?.clinicId,
    })
    if (!!clinicDetails) {
      handleChangeObject({ target: { id: 'clinicId', value: clinicDetails } })
    }
    setLoadingDetails((prev) => ({ ...prev, clinic: false }))
  }

  async function changeClinic(value) {
    setLoadingDetails((prev) => ({ ...prev, clinic: true }))
    const clinicDetails = await GetDetailedEntity(ALL_ENTITY_TYPES?.clinic, { id: value?.id })
    const clinicDataToSet = await transformFetchedEntity(ALL_ENTITY_TYPES.clinic, clinicDetails)
    if (!!clinicDetails) {
      handleChangeObject({ target: { id: 'clinicId', value: clinicDataToSet } })
      handleChangeObject({ target: { id: 'mentorId', value: null } })
    }
    setLoadingDetails((prev) => ({ ...prev, clinic: false }))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  id="enabled"
                  checked={!!imageObject?.enabled || false}
                  onChange={handleChangeObject}
                  disabled={disabled}
                />
              }
              label={!!imageObject?.enabled ? 'Enabled' : 'Disabled'}
            />
          </Grid>
        </Grid>
        <GridSectionWrapper shadow={false} heading="Clinic and doctors details">
          <Grid item xs={12} md={6}>
            <CFormLabel>Clinic Brand</CFormLabel>
            <CustomReactSelect
              id="clinicBrandId"
              value={imageObject?.clinicBrandId}
              options={customOptions?.clinicBrands || []}
              getOptionValue={(object) => object?.id}
              getOptionLabel={(object) => object?.brandName || object?.title}
              // onChange={(value) => handleChangeObject({ target: { id: 'mentorId', value } })}
              onChangeEvent={handleChangeObject}
              selectType={selectTypes.string}
              placeholder="Select a clinic brand..."
              invalid={!imageObject?.clinicBrandId}
              disabled={disabled}
              required
            />
          </Grid>
          {!!imageObject?.clinicBrandId && (
            <>
              <Grid item xs={6}>
                <CFormLabel>Clinic</CFormLabel>
                <CustomAsyncPaginate
                  cacheUniqs={[imageObject?.clinicBrandId]}
                  value={imageObject?.clinicId}
                  isLoading={loadingDetails?.clinic}
                  loadOptions={loadOptionsForAsyncPaginate}
                  getOptionValue={(object) => object.id}
                  getOptionLabel={(object) => object.title}
                  onChange={changeClinic}
                  selectType={selectTypes.object}
                  placeholder="Select a clinic..."
                  debounceTimeout={500}
                  invalid={
                    !imageObject?.clinicId ||
                    imageObject?.clinicId?.clinicBrandId !== imageObject?.clinicBrandId
                  }
                  additional={{
                    page: 0,
                    getFunction: GetSettingsData,
                    otherParams: {
                      entityType: ALL_ENTITY_TYPES?.clinic,
                      clinicBrandId: imageObject?.clinicBrandId,
                      kycStatus: 'approved',
                      enabled: true,
                    },
                    apiUrl: GENERIC_APIS.entity.getPaginated,
                    setLoading: (val) => setLoadingDetails((prev) => ({ ...prev, clinic: val })),
                  }}
                  isDisabled={disabled}
                  required
                />
              </Grid>

              {imageObject?.clinicId && (
                <Grid item xs={6}>
                  <CFormLabel>Mentor</CFormLabel>
                  <CustomReactSelect
                    id="mentorId"
                    value={imageObject?.mentorId}
                    isLoading={loadingDetails?.clinic}
                    options={currentMentors}
                    getOptionValue={(object) => object?.id}
                    getOptionLabel={(object) =>
                      `${object?.name || object?.title || object?.id} (${getRoleTitleForMentor(
                        object,
                      )})`
                    }
                    // onChange={(value) => handleChangeObject({ target: { id: 'mentorId', value } })}
                    onChangeEvent={handleChangeObject}
                    selectType={selectTypes.string}
                    placeholder="Select a mentor..."
                    invalid={
                      !imageObject?.mentorId ||
                      imageObject?.clinicId?.clinicBrandId !== imageObject?.clinicBrandId
                    }
                    disabled={disabled}
                    required
                  />
                </Grid>
              )}
            </>
          )}
        </GridSectionWrapper>
        <GridSectionWrapper shadow={false} heading="OOH Details">
          <Grid item xs={12}>
            <CCFormInput
              id="title"
              label="Title"
              value={imageObject?.title}
              onChange={handleChangeObject}
              placeholder="Enter title..."
              colNumber={12}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <CCFormInput
              id="subText"
              label="Sub Text"
              value={imageObject?.subText}
              onChange={handleChangeObject}
              placeholder="Enter a sub text...."
              colNumber={12}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={3}>
            <CCFormInput
              id="durationInSec"
              label="Message Duration (in seconds)"
              value={imageObject?.durationInSec}
              onChange={handleChangeObject}
              type="number"
              placeholder="Enter a the duration of this message...."
              colNumber={12}
              disabled={disabled}
            />
          </Grid>
        </GridSectionWrapper>
        <GridSectionWrapper shadow={false} heading="OOH Media">
          <Grid item xs={12}>
            <CustomReactSelect
              id="imageType"
              label="Media Type"
              disabled={disabled}
              value={imageObject?.imageType}
              selectType={selectTypes.string}
              options={OOH_MSG_TYPES_ARRAY}
              onChangeEvent={handleChangeObject}
              getOptionValue={(object) => object?.id}
              getOptionLabel={(object) => object?.title}
            />
          </Grid>
          {imageObject?.imageType === OOH_MSG_TYPES.image && (
            <>
              <Grid item xs={12}>
                <EntityBasedImageUploadComponent
                  disabled={disabled}
                  entity={entity}
                  entityObject={imageObject}
                  handleChange={handleChangeObject}
                  noBorder
                />
              </Grid>
              <Grid item xs={12}>
                <CCFormInput
                  id="imageUrl"
                  label="Fallback Image URL"
                  value={imageObject?.imageUrl}
                  onChange={handleChangeObject}
                  placeholder="Enter a image url...."
                  colNumber={12}
                  disabled={disabled}
                />
                {isValidUrl(imageObject?.imageUrl) && (
                  <img
                    src={imageObject?.imageUrl}
                    style={{
                      margin: '10px',
                      maxWidth: '400px',
                      maxHeight: '200px',
                      objectFit: 'contain',
                    }}
                    alt="custom imageurl"
                  />
                )}
              </Grid>
            </>
          )}
          {imageObject?.imageType === OOH_MSG_TYPES.youtube && (
            <>
              <Grid item xs={12}>
                <CCFormInput
                  id="youtubeUrl"
                  label="Youtube URL"
                  value={imageObject?.youtubeUrl}
                  onChange={handleChangeObject}
                  placeholder="Enter a youtube url...."
                  colNumber={12}
                  disabled={disabled}
                />
                {isValidYouTubeUrl(imageObject?.youtubeUrl) && (
                  <ReactPlayer
                    url={imageObject?.youtubeUrl}
                    style={{
                      padding: '10px',
                      maxWidth: '400px',
                      maxHeight: '200px',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CCFormInput
                  id="videoUrl"
                  label="Video URL"
                  value={imageObject?.videoUrl}
                  onChange={handleChangeObject}
                  placeholder="Enter a video url...."
                  colNumber={12}
                  disabled={disabled}
                />
                {isValidUrl(imageObject?.videoUrl) && (
                  <ReactPlayer
                    url={imageObject?.videoUrl}
                    style={{
                      padding: '10px',
                      maxWidth: '400px',
                      maxHeight: '200px',
                    }}
                  />
                )}
              </Grid>
            </>
          )}
        </GridSectionWrapper>
      </Grid>
    </>
  )
}

export default OohMessagesForm
